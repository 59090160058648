import Loading from 'components/Loading/Loading';
import React, { useState } from 'react';
import Pagination from './Pagination';
import useSearchFilter from './hooks/useSearchFilter';
import Empty from 'components/Empty/Empty';
import SearchInput from 'components/Input/SearchInput';

interface PaginationComponentProps<T> {
    data: T[];
    itemsPerPage?: number;
    // eslint-disable-next-line no-unused-vars
    renderItem: (item: T, index: number) => React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    leftSearchComponent?: () => React.ReactNode;
    parentClass?: string;
    searchCol?: string[];
    isLoading: boolean;
}

const DataComponent = <T,>({
    data,
    renderItem,
    parentClass,
    isLoading,
    searchCol,
    leftSearchComponent,
    itemsPerPage = 10,
}: PaginationComponentProps<T>): JSX.Element => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const filteredData = useSearchFilter(data as any, searchQuery, searchCol);

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPage(1);
        setSearchQuery(event.target.value);
    };

    const renderItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        return filteredData?.slice(startIndex, endIndex).map((item, index) => {
            return renderItem(item as any, index);
        });
    };
    const onClearSearchHandler = () => {
        setSearchQuery('');
        setSearchQuery('');
    };

    return (
        <div>
            <div className="d-flex mb-4" >
                <div className='me-auto ' style={{ width: "40%" }}>{leftSearchComponent && leftSearchComponent()}</div>
                {searchCol && (
                    <div className="ms-auto">
                        <SearchInput
                            onChange={handleSearchInputChange}
                            placeholder="Search school"
                            disabled={isLoading}
                            searchQuery={searchQuery}
                            clearInput={onClearSearchHandler}
                        />
                    </div>
                )}
            </div>

            {isLoading ? (
                <Loading />
            ) : !filteredData?.length ? (
                <Empty />
            ) : (
                <>
                    <div className={parentClass}>{renderItems()}</div>
                    <Pagination
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        data={filteredData}
                        itemsPerPage={itemsPerPage}
                    />
                </>
            )}
        </div>
    );
};

export default DataComponent;
