import { ICourseOutline } from "interfaces/outline.interface";
import React, { useState } from "react";
import styled from "styled-components";
import EditNoteModal from "./Modals/EditNoteModal";

const NoteCardComp = ({
	outline,
	refetch,
	index,
}: {
	outline: ICourseOutline;
	refetch(): void
	index: number;
}): JSX.Element => {
	const [edit, setEdit] = useState(false);

	return (
		<Wrapper>
			<EditNoteModal
				outline={outline}
				show={edit}
				refetch={refetch}
				onClose={() => setEdit(false)}
			/>
			<div className="counter">
				<p className="value">{index.toString().padStart(2, "0")}</p>
				<p style={{ fontSize: "8px" }}>Outline</p>
			</div>

			<p className="question">{outline?.topic}</p>
			<EditBtn onClick={() => setEdit(true)}>
				<i className="fas fa-pen"></i>
			</EditBtn>
		</Wrapper>
	);
};

export default NoteCardComp;

const EditBtn = styled.button`
	border: none;
	background-color: transparent;
	height: fit-content;
	color: #0090c1;
`;

const Wrapper = styled.div`
	position:relative;
	width: 48%;
	background-color: white;
	height: 120px;
	border-radius: 10px;
	padding: 20px 25px;
	display: flex;
	margin-bottom: 20px;

	.counter {
		background-color: #0090c1;
		height: 100%;
		width: 12%;
		color: white;
		border-radius: 8px;
		padding: 2px 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 20px;
		p {
			text-align: center;
			font-size: 12px;
		}
		.value {
			font-size: 30px;
		}
	}
	.question {
		text-overflow: ellipsis;
		overflow: hidden;
		// Addition lines for 2 line or multiline ellipsis
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
`;
