import { useMutation } from "@apollo/client";
import { CREATE_FACULTY } from "apollo/faculty.query";
import { FacultyAtom } from "atom/faculty.atom";
import ModalComp from "components/ModalComp";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";
import Input from "components/Input/Input";

interface IProps {
	show: boolean;
	onClose(): void;
}

const CreateFacultyModal = ({ show, onClose }: IProps) => {
	const [createFaculty, { loading }] = useMutation(CREATE_FACULTY);
	const setSchools = useSetRecoilState(FacultyAtom);
	const [name, setName] = useState("");
	const { id } = useParams<{ id: string }>();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			const { data } = await createFaculty({
				variables: { input: { name, school: id } },
			});

			setSchools((old) => [...old, data.createFaculty]);
			notifySuccess("Faculty created successfully")
			setName("")
			onClose();
		} catch (error) {
			notifyError()
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Add Faculty"
			subTitle="Add and manage new faculty"
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>
					<Input
						label="Faculty name"
						className="form-input"
						onChange={(e) => setName(e.target.value)}
						type="text"
						placeholder="Management"
						name="name"
						value={name}
					/>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? "Processing..." : "Create Faculty"}
					</button>
				</form>
			</div >
		</ModalComp >
	);
};

export default CreateFacultyModal;
