import gql from "graphql-tag";

export const GET_SCHOOLS = gql`
	query {
		getSchools {
			id
			logo
			name
			alias
		}
	}
`;

export const GET_SCHOOLS_BY_COUNTRY = gql`
	query ($id: String!) {
		getSchoolsByCountry(country: $id) {
			id
			logo
			name
			alias
		}
	}
`;

export const GET_SCHOOL = gql`
	query GetSchoolByID($id: ID) {
		getSchool(id: $id) {
			id
			name
			faculties {
				id
				name
			}
		}
	}
`;

export const CREATE_SCHOOL = gql`
	mutation CreateSchool($input: CreateSchoolInput! ) {
		createSchool(input: $input) {
			name
			alias
			logo
			country 
		}
	}
`;

export const UPDATE_SCHOOL = gql`
	mutation ($input: UpdateSchoolInput!) {
		updateSchool(input: $input) {
			id
			name
			logo
			alias
		}
	}
`;
