import React, { FC } from "react";
import { Outlet, Navigate } from "react-router-dom";

export type AuthType = {
  isAuth: boolean;
};

const AuthLayout: FC<AuthType> = ({ isAuth }) => {
  if (isAuth) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default AuthLayout;
