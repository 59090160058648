/* eslint-disable react/no-children-prop */
import { IQuestion } from "interfaces/question.interface";
import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

interface QuestionCardProps {
  question: IQuestion;
  index: number;
  // eslint-disable-next-line no-unused-vars
  showEditForm: (question: IQuestion | null) => void;
}

const QuestionCardComp = ({
  question,
  index,
  showEditForm,
}: QuestionCardProps): JSX.Element => {
  return (
    <Wrapper>
      <div className="counter">
        <p className="value">{index.toString().padStart(2, "0")}</p>
        <p style={{ fontSize: "8px" }}>Question</p>
      </div>

      <div className="question">
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {question?.qns}
        </ReactMarkdown>
      </div>
      <EditBtn onClick={() => showEditForm(question)}>
        <i className="fas fa-pen"></i>
      </EditBtn>
      <div className="year">{question?.year}</div>
    </Wrapper>
  );
};

export default QuestionCardComp;

const EditBtn = styled.button`
  border: none;
  background-color: transparent;
  height: fit-content;
  color: #0090c1;
`;

const Wrapper = styled.div`
  position: relative;
  width: 48%;
  background-color: white;
  height: 120px;
  border-radius: 10px;
  padding: 20px 25px;
  display: flex;
  margin-bottom: 20px;
  .year {
    position: absolute;
    bottom: 2px;
    right: 6px;
    background: #f4f4f4;
    border: 1px solid #0090c1;
    border-radius: 10px;
    padding: 8px;
    color: #0090c1;
  }

  .counter {
    background-color: #0090c1;
    height: 100%;
    width: 12%;
    color: white;
    border-radius: 8px;
    padding: 2px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    p {
      text-align: center;
      font-size: 12px;
    }
    .value {
      font-size: 30px;
    }
  }
  .question {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;
