import ModalComp from "components/ModalComp";
import React from "react";
import styled from "styled-components";
import Input from "components/Input/Input";

import { IOutsource } from "interfaces/outsource.interface";
import Moment from "react-moment";
import { CSVLink } from "react-csv";

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	outsource?: IOutsource;
}

const ViewOutsourceModal = ({ show, onClose, outsource }: CreateSchoolProps) => {

	const isAssignment = outsource?.type === "Assignment Work"

	const exportHeaders = [
		{ label: 'Type', key: 'type' },
		{ label: 'Course Title', key: 'course.title' },
		{ label: 'Question', key: 'question' },
		{ label: 'Other Details', key: 'otherDetails.name' },
		{ label: 'Due Date', key: 'dueDate' },
		{ label: 'email', key: 'outsourcer.email' },
		{ label: 'Phone', key: 'outsourcer.phone' },
		{ label: 'Fname', key: 'outsourcer.firstName' },
		{ label: 'Lname', key: 'outsourcer.lastName' },
		{ label: 'Date Joined', key: 'createdAt' },
	];



	// course.name

	return (
		<ModalComp
			show={show}
			hideTopBar={true}
			onClose={onClose}
		>
			<Wrapper className="create-school">
				<div className="d-flex justify-content-between">
					<div className="">
						{/* Content of the div */}
					</div>
					{outsource && (
						<CSVLink
							className="btn btn-primary"
							style={{ width: "120px" }}
							headers={exportHeaders}
							filename={'outsource.csv'}
							data={[outsource]}
						>
							Export CSV
						</CSVLink>
					)}
				</div>


				<p className="text-gray">Outsource Project Details</p>

				<div className="question-info">
					{
						isAssignment && <div className="form-group">
							<Input
								label="Course Title"
								className="form-input "
								type="text"
								defaultValue={outsource?.course?.title}
								disabled={true}
							/>
						</div>
					}
					<div className="form-group">
						<label htmlFor="question">Assignment Question</label>
						<textarea id="question" value={outsource?.question} disabled={true}
							style={{ minHeight: "150px", background: "#fff", paddingLeft: "8px" }} />
					</div>
					<div className="form-group">
						<label htmlFor="info">Other relevant information</label>
						<textarea id="info" value={outsource?.otherDetails} disabled={true}
							style={{ minHeight: "150px", background: "#fff", paddingLeft: "8px" }}
						/>
					</div>
					<div className="card mb-3">
						Due Date
						{outsource?.dueDate && <Moment format="DD dddd, MMMM YYYY">{outsource?.dueDate}</Moment>}
					</div>
					{isAssignment && <div className="card mb-3">
						Due Time
						{outsource?.dueDate && <Moment format="hh:mm A">{outsource?.dueDate}</Moment>}
					</div>
					}

					<div className="">
						<div className="container d-flex justify-content-between">
							<div className="d-flex-col ">
								<div>Phone Number</div>
								<span>{outsource?.outsourcer?.phone}</span>
							</div>
							<div className="d-flex-col">
								<div>First Name</div>
								<span>{outsource?.outsourcer?.firstName}</span>
							</div>
						</div>
						<hr />
						<div className="container d-flex justify-content-between">
							<div className="d-flex-col"><div>Email Address</div>  <span>{outsource?.outsourcer?.email}</span></div>
							<div className="d-flex-col"><div>Last Name</div> <span>{outsource?.outsourcer?.lastName}</span></div>
						</div>

					</div>
				</div>





			</Wrapper>
		</ModalComp >
	);
};

export default ViewOutsourceModal;

const Wrapper = styled.div`
	.icons-tab {
		width: 100%;
		min-height: 20px;
		background-color: #f4f4f4;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.text-gray {
		color: #727272;
		font-weight: 400;
		margin: 20px 0;
	}
	.form-textarea {
		height: 100px;
		resize: none;
		padding-top: 6px;
	}

	.card{
		background: #FFFFFF;
		border: 1px solid #F4F4F4;
		border-radius: 4px;
		padding:12px 9px;
	}
`;
