import { useQuery } from '@apollo/client';
import { GET_FACULTIES_BY_SCHOOL } from 'apollo/faculty.query';
import { FacultyAtom } from 'atom/faculty.atom';
import DataComponent from 'components/DataComponent/DataComponent';
import GoBackComp from 'components/GoBackComp';
import FacultyCardComp from 'components/school/FacultyCardComp';
import CreateFacultyModal from 'components/school/Modals/CreateFacultyModal';
import { IFaculty } from 'interfaces/school.interface';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const FacultyPage = () => {
  const [createFaculty, setCreateFaculty] = useState(false);
  const [faculties, setFaculties] = useRecoilState(FacultyAtom);
  const { id } = useParams<{ id: string }>();
  const { loading } = useQuery(GET_FACULTIES_BY_SCHOOL, {
    variables: { id },
    onCompleted: (data) => setFaculties(data.getFacultiesBySchool),
    onError: (err) => console.log(err),
  });

  const renderItem = (faculty: IFaculty, index: number) => {
    return <FacultyCardComp key={index} faculty={faculty} />;
  };

  return (
    <>
      <CreateFacultyModal show={createFaculty} onClose={() => setCreateFaculty(false)} />
      <Wrapper>
        <div className="d-flex justify-content-between mt-4">
          <div className="flex ">
            <GoBackComp />
            <h2 className="fs-4 mt-5">Manage Faculty</h2>
            <p>Select and manage Faculty</p>
          </div>
          <button className="btn-outline-primary btn rounded-lg" onClick={() => setCreateFaculty(true)}>
            + New Faculty
          </button>
        </div>

        <DataComponent
          isLoading={loading}
          data={faculties}
          itemsPerPage={6}
          renderItem={renderItem}
          parentClass="faculty-list"
        />
      </Wrapper>
    </>
  );
};

export default FacultyPage;

const Wrapper = styled.div`
  .faculty-list {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 4px;
  }
`;
