import { gql } from "@apollo/client";

export const GET_USERS = gql`
	query{
		getUsers{
			id
			email
			phone
			firstName
			lastName
			gender
			createdAt
			updatedAt
			school{
				name
			}
			faculty{
				name
			}
			department{
				name
			}

		}
	}
`;

