import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const GoBackComp = () => {
	const history = useNavigate();
	const handleBack = () => {
		history(-1);
	};
	useEffect(() => {
		console.clear();
	}, []);
	return (
		<Wrapper onClick={handleBack}>
			<div className="icon">
				<i className="fas fa-chevron-left"></i>
			</div>
			<p>Back</p>
		</Wrapper>
	);
};

export default GoBackComp;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	color: #0090c1;
	transition: all 0.3s ease-in-out;
	&:hover {
		transform: scale(0.95);
	}
	.icon {
		height: 40px;
		width: 40px;
		border: 2px solid #0090c1;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 20px;
	}
	font-size: 20px;
	margin-bottom: 20px;
	cursor: pointer;
`;
