import { useLazyQuery } from '@apollo/client';
import { GET_WAIT_LISTS } from 'apollo/waitlist.query';
import { WaitListAtom } from 'atom/waitlist.atom';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import Moment from 'react-moment';
import 'moment-timezone';
import { IWaitList } from 'interfaces/waitlist.interface';
import DataTable from 'react-data-table-component';
import Empty from 'components/Empty/Empty';
import Loading from 'components/Loading/Loading';

const Index = () => {
  const [waitlist, setWaitlist] = useRecoilState(WaitListAtom);

  const [getWaitlist, { loading }] = useLazyQuery(GET_WAIT_LISTS, {
    onCompleted: (data) => setWaitlist(data.getWaitLists),
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (!waitlist.length) {
      getWaitlist();
    }
  }, [waitlist, getWaitlist]);

  const columns = [
    {
      name: 'No',
      cell: function cell(_: IWaitList, index: number) {
        return index + 1;
      },
      width: '80px',
    },
    { name: 'Name of Institution', selector: (row: IWaitList) => row?.schoolName },
    { name: 'Faculty', selector: (row: IWaitList) => row?.faculty },
    { name: 'Department', selector: (row: IWaitList) => row?.department },
    {
      name: 'Email Address',
      cell: function cell(row: IWaitList) {
        return <div style={{ width: '600px' }}>{row?.email}</div>;
      },
    },
    { name: 'Phone Number', selector: (row: IWaitList) => row?.phone },
    {
      name: 'Date Added',
      cell: function cell(row: IWaitList) {
        return row.createdAt ? <Moment format="DD/MM/YYYY">{row.createdAt}</Moment> : '-';
      },
    },
  ];
  return (
    <>
      <Wrapper>
        <div className="d-flex justify-content-between mb-5">
          <div>
            sort <button className="btn btn-primary"> Alphabetical</button>
          </div>
          <CSVLink className="btn btn-primary" filename={'waitlist.csv'} data={waitlist}>
            Export CSV
          </CSVLink>
        </div>

        <DataTable
          columns={columns}
          data={waitlist}
          noDataComponent={<Empty />}
          pagination={true}
          progressPending={loading}
          progressComponent={<Loading />}
          customStyles={{ headCells: { style: { color: '#334D6E', fontSize: '13px' } } }}
          responsive
          striped
        />
      </Wrapper>
    </>
  );
};

export default Index;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
