import { gql } from '@apollo/client';

export const GET_OUTSOURCES = gql`
  query {
    getOutsources {
      id
      type
      dueDate
      done
      question
      otherDetails
      createdAt
      course {
        title
      }
      outsourcer {
        email
        phone
        firstName
        lastName
        department {
          name
        }
        faculty {
          name
        }
        school {
          name
        }
      }
    }
  }
`;

export const GET_OUTSOURCE = gql`
  query ($id: String!) {
    getOutsource(id: $id) {
      id
      question
      otherDetails
      dueDate
      course {
        title
      }
      outsourcer {
        email
        phone
        firstName
        lastName
      }
    }
  }
`;
