import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface IProps {
  size?: 'large' | 'small' | 'middle' | 'xsmall';
  color?: 'primary' | 'secondary' | 'dark' | 'white';
  bold?: boolean;
  font?: number;
  bordered?: boolean;
  height?: number;
  width?: number;
}

const widthMapper: any = {
  xsmall: isMobile ? '80px' : '120px',
  small: isMobile ? '80px' : '120px',
  middle: isMobile ? '260px' : '320px',
  large: '100%',
};

const heightMapper: any = {
  xsmall: isMobile ? '25px' : '30px',
  small: isMobile ? '40px' : '45px',
  middle: isMobile ? '40px' : '45px',
  large: isMobile ? '45px' : '50px',
};

const fontMapper: any = {
  xsmall: isMobile ? '12px' : '14px',
  small: isMobile ? '14px' : '16px',
  middle: isMobile ? '14px' : '16px',
  large: isMobile ? '16px' : '18px',
};

const backgroundColorMapper: any = {
  primary: '#0090c1',
  secondary: '#FF6D05',
  dark: '#4B494D',
  white: '#FFFFFF',
};

const backgroundHoverColorMapper: any = {
  primary: '#18ACDD',
  secondary: '#FF8B38',
  dark: '#7D7980',
  white: '#F0EBF2',
};

const colorMapper: any = {
  primary: '#FFFFFF',
  secondary: '#FFFFFF',
  dark: '#FFFFFF',
  white: '#4B494D',
};
export const Button = styled.button<IProps>`
  background: ${({ color = 'primary', bordered = false }) =>
    bordered ? 'none' : backgroundColorMapper[color]} !important;
  color: ${({ bordered = false, color = 'primary' }) =>
    bordered ? backgroundColorMapper[color] : colorMapper[color]} !important;
  border-radius: 50px !important;
  border: ${({ bordered = false, color = 'primary' }) =>
    bordered ? ` 2px ${backgroundColorMapper[color]} solid` : 'none'} !important;
  width: ${({ width, size = 'medium' }) => (width ? `${width}px` : widthMapper[size])} !important;
  height: ${({ height, size = 'medium' }) =>
    height ? `${height}px` : heightMapper[size]} !important;
  font-size: ${({ font, size = 'medium' }) => (font ? `${font}px` : fontMapper[size])} !important;
  font-weight: ${({ bold = false }) => (bold ? 800 : 500)} !important;
  &:hover {
    background: ${({ bordered = false, color = 'primary' }) =>
    bordered ? backgroundColorMapper[color] : backgroundHoverColorMapper[color]} !important;
    color: ${({ color = 'primary' }) => colorMapper[color]} !important;
  }
`;
