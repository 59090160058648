import React, { FC } from "react";
import CoursesPage from "pages/courses";
import DepartmentPage from "pages/departments";
import FacultyPage from "pages/faculties";
import QuestionPage from "pages/questions";
import SchoolPage from "pages/school";
import WaitListPage from "pages/waitlist";
import OutSourcedPage from "pages/outsource";
import UsersPage from "pages/users";
import SubscribersPage from "pages/subscribers";

import { useRoutes } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardHomePage from "./pages";
import LoginPage from "./pages/login";
import CoursesDetail from "pages/course-details/courseDetails";
import CourseNote from "pages/course-note/courseNote";
import CourseCase from "pages/course-cases/CourseCase";
import CountryPage from "pages/country";

export type AuthType = {
  isAuth: boolean;
};
const RouterComp: FC<AuthType> = ({ isAuth }) => {
  const element = useRoutes([
    // Auth routes
    {
      path: "/",
      element: <DashboardLayout isAuth={isAuth} />,
      children: [
        { path: "", element: <DashboardHomePage /> },
        { path: "/countries", element: <CountryPage /> },
        { path: "/country/:id", element: <SchoolPage /> },
        { path: "/school/:id", element: <FacultyPage /> },
        { path: "/faculties/:id", element: <DepartmentPage /> },
        { path: "/departments/:id", element: <CoursesPage /> },
        { path: "/course/:id", element: <CoursesDetail /> },
        { path: "/course/:id/questions", element: <QuestionPage /> },
        { path: "/course/:id/notes", element: <CourseNote /> },
        { path: "/course/:id/cases", element: <CourseCase /> },
        { path: "/questions", element: <QuestionPage /> },
        { path: "/waitlist", element: <WaitListPage /> },
        { path: "/outsourced", element: <OutSourcedPage /> },
        { path: "/users", element: <UsersPage /> },
        { path: "/subscribers", element: <SubscribersPage /> },
      ],
    },

    /*
     **Guest Routes
     */

    {
      path: "/",
      element: <AuthLayout isAuth={isAuth} />,
      children: [{ path: "/login", element: <LoginPage /> }],
    },
  ]);

  return element;
};

export default RouterComp;
