import { gql, useMutation, useQuery } from '@apollo/client';
import { QuestionAtom } from 'atom/question.atom';
import ModalComp from 'components/ModalComp';
import { ICourse } from 'interfaces/school.interface';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import MarkdownEditor from '@uiw/react-markdown-editor';
import '../../../style/components/CreateSchool.scss';
import Input from 'components/Input/Input';
import { notifyError, notifySuccess } from 'utils/notification';
import { ICourseOutline } from 'interfaces/outline.interface';
import { CREATE_COURSE_OUTLINE } from 'apollo/outline.query';

const GET_COURSE = gql`
  query ($id: String!) {
    getCourse(id: $id) {
      id
      title
      code
      semester
    }
  }
`;

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	getCourseOutline(): void;
}

const CreateNoteModal = ({ show, onClose, getCourseOutline }: CreateSchoolProps) => {
	const [createCourseOutline, { loading }] = useMutation(CREATE_COURSE_OUTLINE);
	const setQuetions = useSetRecoilState(QuestionAtom);
	const [info, setInfo] = useState<Partial<ICourseOutline>>();
	const { id } = useParams<{ id: string }>();
	const [course, setCourse] = useState<ICourse>();

	useQuery(GET_COURSE, {
		variables: { id },
		onCompleted: (data) => setCourse(data.getCourse),
		onError: (err) => console.log(err),
	});

	const handleMarkDownChange = (name: string, value: string) => {
		setInfo({
			...info,
			[name]: value,
		});
	};
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;

		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = { ...info, course: id };
		try {
			const { data } = await createCourseOutline({ variables: { input } });
			setQuetions((old) => [...old, data.createQuestion]);
			notifySuccess('Note created successfully');
			getCourseOutline();
			setInfo({ topic: '', note: '', });
			onClose();
		} catch (error: any) {
			notifyError(error?.message);
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Add Course Notes"
			subTitle="Add new course outline and corresponing notes"
		>
			<Wrapper className="create-school">
				<p className="text-gray"> Enter Course Details</p>
				<form onSubmit={handleSubmit}>
					<div className="question-info">
						<Input
							label="Course Title"
							className="form-input mb-3"
							type="text"
							placeholder="Management"
							defaultValue="title"
							value={course?.title}
							disabled={true}
						/>
						<Input
							label="Select Course Outline title"
							className="form-input mb-3"
							type="text"
							placeholder="Friction and it causes"
							onChange={handleChange}
							name="topic"
							value={info?.topic}
						/>
					</div>
					<p className="text-gray mt-2">Enter Question & Answer</p>

					<div className="form-group row mb-5">
						<label>Paste or Type Course Note Here</label>
						<MarkdownEditor
							id="note"
							style={{ height: '120px' }}
							value={info?.note}
							onChange={(value) => handleMarkDownChange('note', value)}
						/>
					</div>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? 'Processing...' : 'Save Note'}
					</button>
				</form>
			</Wrapper>
		</ModalComp>
	);
};

export default CreateNoteModal;

const Wrapper = styled.div`
  .icons-tab {
    width: 100%;
    min-height: 20px;
    background-color: #f4f4f4;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text-gray {
    color: #727272;
    font-weight: 400;
    margin: 20px 0;
  }
  .form-textarea {
    height: 100px;
    resize: none;
    padding-top: 6px;
  }
`;
