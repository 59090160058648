import styled from "styled-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ICourse } from "interfaces/school.interface";
import EditCourseModal from "./Modals/EditCourseModal";
//

const CourseCardComp = ({ course }: { course: ICourse }) => {
	const [edit, setEdit] = useState(false);
	return (
		<Wrapper>
			<EditCourseModal
				course={course}
				show={edit}
				onClose={() => setEdit(false)}
			/>
			<EditBtn
				className="btn text-primary edit-btn"
				onClick={() => setEdit(true)}
			>
				<i className="fas fa-pen"></i>
			</EditBtn>
			<Link to={`/course/${course?.id}`}>
				<div className="faculty-item">
					<img src="/course-icon.png" className="icon" alt="" />
					<div className="mt-2">
						<p className="text-capitalize text-primary">{course?.code}</p>
					</div>
					<div className="d-grid mt-2">
						{/* <Link to={`/schools/${school?.id}`} style={{ width: "100%" }}> */}
						<button
							className="btn btn-outline-primary"
							style={{ width: "100%" }}
						>
							Open
						</button>
						{/* </Link> */}
					</div>
					{/* <div className="action">
            <i className="fas fa-long-arrow-alt-right"></i>
          </div> */}
				</div>
			</Link>
		</Wrapper>
	);
};

export default CourseCardComp;

const EditBtn = styled.button`
	position: absolute;
	right: 5%;
	top: 5%;
	border: 1px solid #0090c1;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	&:hover {
		background-color: #0090c1;
		.fas {
			color: white;
		}
	}
`;
const Wrapper = styled.div`
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	width: 18%;
	margin: 20px 1%;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	&:hover ${EditBtn} {
		opacity: 1;
	}
	&:hover .action {
		opacity: 1;
	}

	.icon {
		width: 60% !important;
		height: auto !important;
	}
	.ms-3 {
		max-width: 60%;
	}
	.text-capitalize {
		font-weight: bold !important;
	}
	.d-grid {
		width: 100%;
	}

	.faculty-item {
		height: 100%;
		width: 100%;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	p {
		margin: 0;
	}
	.action {
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease-in-out;
		border-radius: 50%;
		background-color: #e3f3f8;
		position: absolute;
		right: 1rem;
		opacity: 0;
		.fas {
			color: #0090c1;
		}
	}
`;
