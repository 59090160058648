import { gql, useMutation, useQuery } from "@apollo/client";
import { UPDATE_QUESTION } from "apollo/question.query";
import { QuestionAtom } from "atom/question.atom";
import ModalComp from "components/ModalComp";
import { IQuestion } from "interfaces/question.interface";
import { ICourse } from "interfaces/school.interface";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import styled from "styled-components";
import "../../../style/components/CreateSchool.scss";
import Input from "components/Input/Input";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { notifyError, notifySuccess } from "utils/notification";

const GET_COURSE = gql`
  query ($id: String!) {
    getCourse(id: $id) {
      id
      title
      code
      semester
    }
  }
`;
interface CreateSchoolProps {
  show: boolean;
  onClose(): void;
  question: IQuestion | null;
}

const EditQuestionModal = ({ show, onClose, question }: CreateSchoolProps) => {

  const [updateQuestion, { loading }] = useMutation(UPDATE_QUESTION);
  const setQuetions = useSetRecoilState(QuestionAtom);
  const [info, setInfo] = useState<Partial<IQuestion>>({});
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<ICourse>();

  useEffect(() => {
    if (question) {
      setInfo(question);
    }
  }, []);

  useQuery(GET_COURSE, {
    variables: { id },
    onCompleted: (data) => setCourse(data.getCourse),
    onError: (err) => console.log(err),
  });
  const handleMarkDownChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const input = {
      ans: info?.ans,
      course: info?.course?.id,
      id: info?.id,
      qns: info?.qns,
      year: info?.year,
    };
    try {
      await updateQuestion({ variables: { input } });

      setQuetions((old) =>
        old.map((q) => (q.id === info.id ? { ...q, ...info } : q))
      );
      notifySuccess("Question edited successfully");
      onClose();
    } catch (error: any) {
      notifyError(error?.message);
    }
  };

  return (
    <ModalComp
      show={show}
      onClose={onClose}
      title="Questions & Answers"
      subTitle="Edit questions and answers"
    >
      <Wrapper className="create-school">
        <p className="text-gray">Select Course Details</p>

        <form onSubmit={handleSubmit}>
          <div className="question-info">
            <div className="form-group">
              <Input
                label="Course Title"
                className="form-input mb-3"
                type="text"
                placeholder="Management"
                name="title"
                value={course?.title}
                disabled={true}
              />
            </div>

            <div className="form-group row">
              <select
                className="form-select col-6"
                onChange={handleChange}
                name="year"
                value={info?.year}
                required
              >
                <option value="">Select Year</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
              </select>
            </div>
          </div>
          <p className="text-gray mt-2">Enter Question & Answer</p>

          <div className="form-group row mb-4">
            <label>Question</label>
            <MarkdownEditor
              id="qns"
              style={{ height: "96px" }}
              value={info?.qns}
              onChange={(value) => handleMarkDownChange("qns", value)}
            />
          </div>
          <div className="form-group">
            <label>Answer</label>
            <MarkdownEditor
              id="ans"
              style={{ height: "96px" }}
              value={info?.ans}
              onChange={(value) => handleMarkDownChange("ans", value)}
            />
          </div>

          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Processing..." : "Update Question"}
          </button>
        </form>
      </Wrapper>
    </ModalComp>
  );
};

export default EditQuestionModal;

const Wrapper = styled.div`
  .icons-tab {
    width: 100%;
    min-height: 20px;
    background-color: #f4f4f4;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text-gray {
    color: #727272;
    font-weight: 400;
    margin: 20px 0;
  }
  .form-textarea {
    height: 100px;
    resize: none;
    padding-top: 6px;
  }
`;
