import { ISchool } from "interfaces/school.interface";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import EditSchoolModal from "./Modals/EditSchoolModal";

const SchoolCardComp = ({ school }: { school: ISchool }) => {
	const [showEdit, setShowEdit] = useState(false);
	return (
		<Wrapper>
			<EditSchoolModal
				schoolData={school}
				show={showEdit}
				onClose={() => setShowEdit(false)}
			/>
			<div className="edit-sch">
				<button className="btn text-primary" onClick={() => setShowEdit(true)}>
					<i className="fas fa-pen"></i>  <span>Edit</span>
				</button>
			</div>
			<div className="inner d-flex">
				<img src={school?.logo} alt="" />
				<div className="ms-3 mt-3">
					<p className="text-uppercase text-primary">{school?.alias}</p>
					<p className="text-capitalize fs-14 ms-2">{school?.name}</p>
				</div>
			</div>

			<div className="d-grid mt-3">
				<Link to={`/school/${school?.id}`} style={{ width: "100%" }}>
					<button className="btn btn-outline-primary" style={{ width: "100%" }}>
						Open
					</button>
				</Link>
			</div>
		</Wrapper>
	);
};

export default SchoolCardComp;

const Wrapper = styled.div`
	background-color: #fff;
	max-width:500px;
	border-radius: 10px;
	padding: 2rem;
	position:relative;
	margin: 20px 10px;
	.text-capitalize {
		min-height: 42px;
	}
	
	
	p {
		margin: 0;
	}
	.edit-sch{
		position:absolute;
		right:0;
		top:0;
		padding:10px;
		box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		button{
			display:flex;
			gap:12px;
			align-items:center;

		}
		&:hover{
			background:rgba(0, 0, 0, 0.05);;
		}
	}
	.btn {
		&:hover {
			color: white;
		}
	}
`;
