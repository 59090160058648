import React from 'react';

function Loading() {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
            <div className="d-flex align-items-center">
                <div
                    className="spinner-border text-dark me-2"
                    role="status"
                    style={{ width: '1.5rem', height: '1.5rem' }}
                ></div>
                <span className="text-dark fw-medium">Loading...</span>
            </div>
        </div>
    );
}

export default Loading;
