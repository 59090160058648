import React from 'react'

function Pagination({ currentPage, setCurrentPage, data, itemsPerPage }: {
    currentPage: number,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    data: any,
    itemsPerPage: number,
}) {

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const getPaginationRange = () => {
        const halfVisiblePages = Math.floor(itemsPerPage / 2);
        let start = currentPage - halfVisiblePages;
        let end = currentPage + halfVisiblePages;

        if (start < 1) {
            start = 1;
            end = Math.min(itemsPerPage, totalPages);
        }

        if (end > totalPages) {
            end = totalPages;
            start = Math.max(1, totalPages - itemsPerPage + 1);
        }

        return Array.from({ length: Math.min(end - start + 1, totalPages) }, (_, index) => start + index);
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;


    return (
        <div className="d-flex justify-content-end mt-4">
            <button
                className="mx-2 px-3 py-1 rounded bg-gray-200 text-gray-700"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={isFirstPage}
            >
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.16016 1.41L3.58016 6L8.16016 10.59L6.75016 12L0.750156 6L6.75016 0L8.16016 1.41Z" fill="#C4CDD5" />
                </svg>

            </button>

            {getPaginationRange().map((pageNumber) => (
                <button
                    key={pageNumber}
                    className={`mx-2 px-3 py-1 rounded ${pageNumber === currentPage ? 'btn btn-primary' : 'btn bg-white border border-secondary'}`}
                    onClick={() => handlePageChange(pageNumber)}
                >
                    {pageNumber}
                </button>
            ))}

            <button
                className="mx-2 px-3 py-1 rounded bg-gray-200 text-gray-700"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={isLastPage}
            >
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.839844 1.41L5.41984 6L0.839844 10.59L2.24984 12L8.24984 6L2.24984 0L0.839844 1.41Z" fill="#C4CDD5" />
                </svg>


            </button>
            <p className="mx-4 mt-2 text-gray-700">
                Page {currentPage} of {totalPages}
            </p>
        </div>

    )
}

export default Pagination