import React from "react";
import "../style/components/ModalComp.scss";

interface Property {
  show: boolean;
  children: any;
  onClose(): void;
  title?: String;
  subTitle?: String;
  hideTopBar?: boolean;
  style?: { inner: any }
}

const ModalComp = ({ show, title, subTitle, hideTopBar = false, children, style, onClose }: Property) => {

  return (
    <div className={show ? "modal-container" : "d-none"}>
      <div className="modal-content">
        <div className="close" onClick={onClose}>
          X
        </div>
        {!hideTopBar && <div className="top-bar">
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle} </p>
        </div>}
        <div className="children" style={style?.inner}>{children}</div>
      </div>
    </div>
  );
};

export default ModalComp;
