import { useMutation } from "@apollo/client";
import { SchoolAtom } from "atom/school.atom";
import ModalComp from "components/ModalComp";
import { ISchool } from "interfaces/school.interface";
import React, { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UPDATE_SCHOOL } from "../../../apollo/school.query";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";
import Input from "components/Input/Input";

interface IProp {
	show: boolean;
	onClose(): void;
	schoolData: ISchool;
}

const EditSchoolModal = ({ show, onClose, schoolData }: IProp) => {
	const [updateSchool, { loading }] = useMutation(UPDATE_SCHOOL);
	const setSchools = useSetRecoilState(SchoolAtom);
	const uploadRef = useRef<HTMLInputElement>(null);
	const [filePreview, setFilePreview] = useState("");
	const [school, setSchool] = useState<Partial<ISchool>>(schoolData);


	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setSchool({
			...school,
			[name]: value,
		});
	};
	const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		const reader = new FileReader();

		if (files && files.length > 0) {
			reader.readAsDataURL(files[0]);
			reader.onloadend = () => {
				if (reader.result) {
					setFilePreview(reader.result as string);
				}
			};
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = {
			...school,
			logo: filePreview ? filePreview : schoolData.logo,
		};

		try {
			const { data } = await updateSchool({ variables: { input } });

			setSchools((old) =>
				old.map((school) =>
					school.id === schoolData.id
						? { ...school, ...data.updateSchool }
						: school,
				),
			);
			notifySuccess("School edited successfully")

			onClose();
		} catch (error) {
			notifyError();
			console.log(error);
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Edit School"
			subTitle=""
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>
					<Input
						label="School name"
						className="form-input mb-3"
						onChange={handleChange}
						type="text"
						placeholder="University of Port Harcourt"
						name="name"
						value={school?.name}
					/>
					<Input
						label="Alias of School"
						className="form-input mb-3"
						name="alias"
						type="text"
						onChange={handleChange}
						placeholder="UNIPORT"
						value={school?.alias}
					/>


					<div className="form-group" style={{ position: "relative" }}>
						<label style={{ position: "absolute", top: "-15px", left: "10px", padding: "4px", background: "white", zIndex: "100px" }}>Upload school logo</label>
						<div
							className="upload-image"
							onClick={() => uploadRef.current?.click()}
						>
							<img
								src={
									filePreview
										? filePreview
										: schoolData?.logo || "/image-placeholder.png"
								}
								alt="preview"
								width="80"
							/>
							<p style={{}}>Upload your image</p>
							<p style={{ fontSize: 12, color: "#9F9F9F", margin: 0 }}>
								Support; JPG, JPEG, PNG
							</p>
						</div>

						<input
							className="d-none"
							type="file"
							onChange={handleImage}
							ref={uploadRef}
						/>
					</div>
					<button className="btn btn-primary mt-3" disabled={loading}>
						{loading ? "Processing..." : "Update School"}
					</button>
				</form>
			</div>
		</ModalComp>
	);
};

export default EditSchoolModal;

// const Wrapper = styled.div``;
