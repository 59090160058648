import { gql } from "@apollo/client";

export const CREATE_DEPARTMENT = gql`
	mutation ($input: CreateDepartmentInput!) {
		createDepartment(input: $input) {
			id
			name
			levels
		
		}
	}
`;

export const UPDATE_DEPARTMENT = gql`
	mutation ($input: UpdateDepartmentInput!) {
		updateDepartment(input: $input) {
			id
			name
		}
	}
`;

export const GET_DEPARTMENTS = gql`
	{
		getDepartments {
			id
			name
		}
	}
`;

export const GET_DEPARTMENTS_BY_FACULTY = gql`
	query ($id: String!) {
		getDepartmentsByFaculty(faculty: $id) {
			id
			name
			levels
		}
	}
`;

export const GET_DEPARTMENT = gql`
	query ($id: String!) {
		getDepartment(id: $id) {
			id
			name
		}
	}
`;
