import { useLazyQuery } from '@apollo/client';
import { GET_COURSE_OUTLIN_BY_COURSE } from 'apollo/outline.query';
import { OutlinesAtom } from 'atom/outline.atom';
import DataComponent from 'components/DataComponent/DataComponent';
import GoBackComp from 'components/GoBackComp';
import CreateNoteModal from 'components/school/Modals/CreateNoteModal';
import NoteCardComp from 'components/school/NoteCardComp';
import { ICourseOutline } from 'interfaces/outline.interface';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const CourseNote = () => {
    const [createOutline, setCreateOutline] = useState(false);

    const [outlines, setOutlines] = useRecoilState(OutlinesAtom);
    const { id } = useParams<{ id: string }>();
    const [getCourseOutline, { loading, refetch }] = useLazyQuery(GET_COURSE_OUTLIN_BY_COURSE, {
        onCompleted: (data) => setOutlines(data.getCourseOutlinByCourse),
        onError: (error) => console.log(error),
        variables: { id },
    });

    useEffect(() => {
        getCourseOutline({ variables: { id } });
    }, [getCourseOutline, id]);

    const renderItem = (outline: ICourseOutline, index: number) => {
        return <NoteCardComp outline={outline} index={index + 1} key={index} refetch={refetch} />;
    };

    return (
        <div>
            <CreateNoteModal
                show={createOutline}
                getCourseOutline={refetch}
                onClose={() => setCreateOutline(false)}
            />
            <Wrapper>
                <div className="d-flex justify-content-between">
                    <div className="flex">
                        <GoBackComp />
                        <h2 className="fs-4">Manage Notes</h2>
                        <p>Add and manage Course Outlines</p>
                    </div>
                    <button className="btn-outline-primary btn" onClick={() => setCreateOutline(true)}>
                        + New Outline
                    </button>
                </div>

                <DataComponent
                    isLoading={loading}
                    data={outlines}
                    itemsPerPage={6}
                    renderItem={renderItem}
                    searchCol={['topic']}
                    parentClass="school-list row"
                />
            </Wrapper>
        </div>
    );
};

export default CourseNote;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
