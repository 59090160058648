import { useQuery } from '@apollo/client';
import { GET_OUTSOURCES } from 'apollo/outsource.query';
import React, { useState } from 'react';
import styled from 'styled-components';
import 'moment-timezone';
import { Button } from 'components/Button';
import ViewOutsourceModal from 'components/outsource/ViewOutsourceModal';
import DataTable from 'react-data-table-component';
import { IOutsource } from 'interfaces/outsource.interface';
import Moment from 'react-moment';
import useSearchFilter from 'components/DataComponent/hooks/useSearchFilter';
import SearchInput from 'components/Input/SearchInput';
import Empty from 'components/Empty/Empty';
import Loading from 'components/Loading/Loading';

const Index = () => {
  const [outsource, setOutsource] = useState<IOutsource>();
  const [showOutsourceDetails, setShowOutsourceDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, loading } = useQuery(GET_OUTSOURCES);
  const filteredData = useSearchFilter(data?.getOutsources as any, searchQuery, [
    "outsourcer.department.name",
    "outsourcer.school.name",
    "outsourcer.faculty.name",
    "outsourcer.department.name",
    "outsourcer.email",
  ]);

  const showOutsourceDetailsHandler = (selectedData: IOutsource) => {
    setOutsource(selectedData);
    setShowOutsourceDetails(true);
  };

  const onCloseModal = () => {
    setOutsource(undefined);
    setShowOutsourceDetails(false);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onClearSearchHandler = () => {
    setSearchQuery('');
    setSearchQuery('');
  };

  const columns = getColumn({ showOutsourceDetailsHandler });
  return (
    <>
      <ViewOutsourceModal
        outsource={outsource}
        show={showOutsourceDetails}
        onClose={onCloseModal}
      />
      <Wrapper>
        <div className="d-flex justify-content-between mb-3">
          <div>
          </div>
          <SearchInput
            onChange={handleSearchInputChange}
            placeholder="Search anything here."
            disabled={loading}
            searchQuery={searchQuery}
            clearInput={onClearSearchHandler}
          />
        </div>

        <DataTable
          data={filteredData as unknown as IOutsource[]}
          noDataComponent={<Empty />}
          pagination={true}
          progressPending={loading}
          progressComponent={<Loading />}
          columns={columns}
          customStyles={{ headCells: { style: { color: '#334D6E', fontSize: '13px' } } }}
          responsive
          striped
        />
      </Wrapper>
    </>
  );
};

export default Index;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;

const getColumn = ({
  showOutsourceDetailsHandler,
}: {
  // eslint-disable-next-line no-unused-vars
  showOutsourceDetailsHandler: (selectedData: IOutsource) => void;
}) => {
  const columns = [
    {
      name: 'No',
      cell: function cell(_: IOutsource, index: number) {
        return index + 1;
      },
      width: '80px',
    },
    {
      name: 'Name of Institution',
      sortable: true,
      selector: (row: IOutsource) => row?.outsourcer?.school?.name,
      cell: function cell(row: IOutsource) {
        return <div>{row?.outsourcer?.school?.name}</div>;
      },
    },
    {
      name: 'Faculty',
      sortable: true,
      selector: (row: IOutsource) => row?.outsourcer?.faculty?.name,
      cell: function cell(row: IOutsource) {
        return <div>{row?.outsourcer?.faculty?.name}</div>;
      },
    },
    {
      name: 'Department',
      sortable: true,
      selector: (row: IOutsource) => row?.outsourcer?.department?.name,
      cell: function cell(row: IOutsource) {
        return <div>{row?.outsourcer?.department?.name}</div>;
      },
    },
    {
      name: 'Email Address',
      sortable: true,
      selector: (row: IOutsource) => row?.outsourcer?.email,
      cell: function cell(row: IOutsource) {
        return <div>{row?.outsourcer?.email}</div>;
      },
      width: "200px"
    },
    { name: 'Phone Number', selector: (row: IOutsource) => row?.outsourcer?.phone },
    { name: 'Outsource Type', selector: (row: IOutsource) => row?.type },
    {
      name: 'View',
      cell: function cell(row: IOutsource) {
        return (
          <Button onClick={() => showOutsourceDetailsHandler(row)} size="xsmall" className="p-0">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3255_11732)">
                <path
                  d="M14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667ZM5.66667 9L7.33333 11.0067L9.66667 8L12.6667 12H3.33333L5.66667 9Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3255_11732">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            View
          </Button>
        );
      },
    },
    {
      name: 'Request Date',
      cell: function cell(row: IOutsource) {
        return <Moment format="DD/MM/YYYY @ h:mm A">{row?.createdAt}</Moment>;
      },
    },
    {
      name: 'Action',
      cell: function cell(row: IOutsource) {
        return (
          <div>
            Mark Done <input type="checkbox" defaultChecked={!!row.done} />{' '}
          </div>
        );
      },
    },
  ];

  return columns;
};
