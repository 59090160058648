import { gql, useLazyQuery } from "@apollo/client";
import { CourseDetailAtom } from "atom/courseAtom";
import GoBackComp from "components/GoBackComp";
import CourseDetailsCard from "components/course-deatils/CourseDetailsCard";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";

export const GET_COURSE = gql`
	query ($id: String!) {
		getCourse(id: $id) {
			id
			title
			level
			semester
			code
			
		}
	}
`;



const CoursesDetail = () => {
    const [, setCreateCourse] = useState(false);

    const [course, setCourse] = useRecoilState(CourseDetailAtom);
    const { id } = useParams<{ id: string }>();
    const [getCourse, { loading }] = useLazyQuery(GET_COURSE, {
        onCompleted: (data) => setCourse(data.getCourse
        ),
        onError: (error) => console.log(error),
        variables: { id, filter: { search: "" } },
    });

    useEffect(() => {
        getCourse({ variables: { id, filter: { search: "" } } });
    }, [getCourse, id]);

    return (
        <>
            <Wrapper>
                <div className="d-flex justify-content-between">
                    <div className="flex">
                        <GoBackComp />
                        <p className="fs-4">Manage Courses</p>
                        <p>Select and manage each course</p>
                    </div>

                    <div className="d-flex flex-column">
                        <Link
                            className="btn-outline-primary btn mb-3"
                            to={`/course/${id}/questions`}
                            onClick={() => setCreateCourse(true)}
                        >
                            Manage Questions
                        </Link>
                        <Link
                            className="btn-outline-primary btn mb-3"
                            to={`/course/${id}/notes`}
                            onClick={() => setCreateCourse(true)}
                        >
                            Manage Notes
                        </Link>
                        <Link
                            className="btn-outline-primary btn"
                            to={`/course/${id}/cases`}
                            onClick={() => setCreateCourse(true)}
                        >
                            Manage Cases
                        </Link>

                    </div>
                </div>


                <div className="school-list">
                    {loading ? (
                        <p>loading...</p>
                    ) : (
                        course &&
                        <CourseDetailsCard course={course.title} level={course.level} semester={course.semester} code={course.code} />
                    )}
                </div>
            </Wrapper>
        </>
    );
};

export default CoursesDetail;

const Wrapper = styled.div`
	margin-top:48px;
	.school-list {
		margin-top: 2rem;

		justify-content: space-between;

		img {
			width: 61px;
			height: 58px;
		}
	}
`;
