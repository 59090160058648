import { useState, useEffect } from 'react';

interface DataItem {
    id: number;
    name: string;
    [key: string]: any;
}

const useSearchFilter = (data: DataItem[], searchKey: string, searchCol?: string[]): DataItem[] => {
    const [filteredData, setFilteredData] = useState<DataItem[]>(data);


    function getProperty(obj: any, property: any) {
        const nestedProperties = property.split('.');
        for (let i = 0; i < nestedProperties.length; i++) {
            if (!obj || !(nestedProperties[i] in obj)) {
                return undefined;
            }
            obj = obj[nestedProperties[i]];
        }

        return obj;
    }

    useEffect(() => {
        if (searchKey && searchCol?.length) {
            const filtered = data.filter(item => {
                return searchCol.some(property => {
                    const value = getProperty(item, property);
                    return value && value.toLowerCase().includes(searchKey.toLowerCase());
                });
            });


            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
        // eslint-disable-next-line
    }, [data, searchKey]);

    return filteredData;
};

export default useSearchFilter;
