import { gql } from '@apollo/client';

export const GET_COURSE = gql`
  query ($id: String!) {
    getCourse(id: $id) {
      id
      title
      level
      semester
      code
      questions {
        id
        qns
        ans
      }
    }
  }
`;

export const GET_COURSES_BY_DEPARTMENT = gql`
  query ($id: String!) {
    getCoursesByDepartment(department: $id) {
      id
      title
      code
      level
      semester
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation ($input: CreateCourseInput!) {
    createCourse(input: $input) {
      id
      code
      title
      level
      semester
      department {
        id
        name
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation ($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      id
      code
      title
      level
      semester
    }
  }
`;
