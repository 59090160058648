import styled from "styled-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IFaculty } from "interfaces/school.interface";
import EditFacultyModal from "./Modals/EditFacultyModal";

const FacultyCardComp = ({ faculty }: { faculty: IFaculty }) => {
  const [edit, setEdit] = useState(false);
  return (
    <Wrapper>
      <EditFacultyModal
        show={edit}
        onClose={() => setEdit(false)}
        facultyData={faculty}
      />
      <EditBtn
        className="btn text-primary edit-btn"
        onClick={() => setEdit(true)}
      >
        <i className="fas fa-pen"></i>
      </EditBtn>
      <Link to={`/faculties/${faculty?.id}`}>
        <div className="faculty-item">
          <div className="ms-3">
            <p className="text-capitalize text-primary">{faculty?.name}</p>
          </div>
          <div className="action">
            <i className="fas fa-long-arrow-alt-right"></i>
          </div>
        </div>
      </Link>
    </Wrapper>
  );
};

export default FacultyCardComp;

const EditBtn = styled.button`
  position: absolute;
  right: 1rem;
  top: 32%;
  border: 1px solid #0090c1;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #0090c1;
    .fas {
      color: white;
    }
  }
`;
const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 0;
  width: 320px;
  height: 100px;
  margin-bottom:25px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:hover ${EditBtn} {
    right: 4rem;
  }
  &:hover .action {
    opacity: 1;
  }

  .ms-3 {
    max-width: 60%;
    .levels {
      color: #727272;
      font-size: 14px;
    }
  }

  .faculty-item {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }
  .action {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    background-color: #e3f3f8;
    position: absolute;
    right: 1rem;
    opacity: 0;
    .fas {
      color: #0090c1;
    }
  }
`;
