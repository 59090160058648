import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
	query{
		getTransactions{
			id
			amount
			createdAt
			expiresAt
			paidAt
			paymentType
			plan
			reference
			transactionId
			user{
				id
				name
				email
				phone
				firstName
				lastName
				gender
				subscriptionCount
				school{
					id
					name
				}
				faculty{
					id
					name
				}
				department{
					id
					name
				}
			}
		}

	}
`;
