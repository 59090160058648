import gql from "graphql-tag";

export const GET_ME = gql`
	{
		me {
			id
			name
			email
			phone
			google_id
		}
	}
`;



export const REGISTER = gql`
	mutation ($input: SignupInput) {
		signup(input: $input) {
			name
			firstName
			lastName
			phone
		}
	}
`;

export const LOGIN_WITH_PHONE = gql`
	mutation ($input: LoginWithPhoneInput) {
		loginWithPhone(input: $input) {
			id
			token
			isActive
		}
	}
`;

export const LOGIN = gql`
	mutation login($email:String! $password: String!)  {
		login(input: {username: $email, password: $password }) {
			email
			token
			role
		}
	}
`;
export const RESEND_OTP = gql`
	mutation ($phone: String) {
		resendOtp(phone: $phone) {
			phone
		}
	}
`;

export const VERIFY_OTP = gql`
	mutation ($otp: Int) {
		verifyOTP(otp: $otp) {
			name
		}
	}
`;
