import React from 'react'
import PropTypes from 'prop-types'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}


const Input: React.FC<InputProps> = ({ label = "", ...rest }) => {
    return (
        <div className="form-group" style={{ position: "relative" }}>
            {!!label && <label style={{ position: "absolute", top: "-15px", left: "10px", padding: "4px", background: "white", zIndex: "100px" }}>{label}</label>}
            <input
                className="form-input"
                {...rest}
            />
        </div>

    )
}



Input.propTypes = {
    label: PropTypes.string,
}


export default Input
