import SidebarComp from "components/Sidebar";
import React, { FC } from "react";
import styled from "styled-components";
import { Outlet, Navigate } from "react-router-dom";

export type AuthType = {
  isAuth: boolean;
};

const DashboardLayout: FC<AuthType> = ({ isAuth }) => {
  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <Wrapper>
      <div className="dashboard">
        <div className="dashboard-inner">
          <div
            style={{
              position: "sticky",
              top: "0",
              maxHeight: "100vh",
              width: "345px",
            }}
          >
            <SidebarComp />
          </div>
          <div className="dashboard-main">
            <div className=" dashboard-content">
              <div className="banner">
                <img src="/pattern.svg" alt="" className="logo" />
              </div>

              <div className="main-page ">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardLayout;

const Wrapper = styled.div`
  .dashboard {
    min-height: 100vh;
    max-width: 100vw;

    &-inner {
      display: flex;
    }

    .banner {
      margin-top: 73px;
      width: 100%;
      min-height: 155px;
      img {
        width: 100%;
      }
    }
    &-main {
      background-color: #e5e5e5;
      width: 100%;
      overflow-x: scroll;
    }

    .dashboard-content {
      display: flex;
      flex-direction: column;
      margin: 0 4em;
    }
  }
`;
