import { gql, useMutation, useQuery } from "@apollo/client";
import ModalComp from "components/ModalComp";
import { ICourse } from "interfaces/school.interface";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import "../../../style/components/CreateSchool.scss";
import Input from "components/Input/Input";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { ICourseOutline } from "interfaces/outline.interface";
import { UPDATE_COURSE_OUTLINE } from "apollo/outline.query";
import { notifyError, notifySuccess } from "utils/notification";



const GET_COURSE = gql`
	query ($id: String!) {
		getCourse(id: $id) {
			id
			title
			code
			semester
		}
	}
`;
interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	refetch(): void;
	outline: ICourseOutline;
}

const EditNoteModal = ({ show, onClose, outline, refetch }: CreateSchoolProps) => {



	const [updateOutline, { loading }] = useMutation(UPDATE_COURSE_OUTLINE);

	const [info, setInfo] = useState<Partial<ICourseOutline>>(outline);
	const { id } = useParams<{ id: string }>();
	const [course, setCourse] = useState<ICourse>();

	useQuery(GET_COURSE, {
		variables: { id },
		onCompleted: (data) => setCourse(data.getCourse),
		onError: (err) => console.log(err),
	});
	const handleMarkDownChange = (name: string, value: string) => {

		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
	) => {
		const { name, value } = e.target;
		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = {
			...info,
			course: course?.id,

		};

		try {
			await updateOutline({ variables: { input } });


			notifySuccess("Note edited successfully");
			refetch()
			onClose();
		} catch (error: any) {
			notifyError(error?.message)
		}
	};
	useEffect(() => {
		console.log(info)


	}, [info])

	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Edit Notes"
			subTitle="Edit Note"
		>
			<Wrapper className="create-school">
				<p className="text-gray">Enter Course Details</p>

				<form onSubmit={handleSubmit}>
					<div className="question-info">
						<Input
							label="Course Title"
							className="form-input mb-3"
							type="text"
							placeholder="Management"
							defaultValue="title"
							value={course?.title}
							disabled={true}
						/>
						<Input
							label="Select Course Outline title"
							className="form-input mb-3"
							type="text"
							placeholder="Friction and it causes"
							onChange={handleChange}
							name="topic"
							value={info?.topic}
						/>
					</div>
					<p className="text-gray mt-2">Enter Question & Answer</p>

					<div className="form-group row mb-5">
						<label>Paste or Type Course Note Here</label>
						<MarkdownEditor
							id="note"
							style={{ height: "120px" }}
							value={info?.note}
							onChange={(value) => handleMarkDownChange("note", value)}
						/>
					</div>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? "Processing..." : "Edit Note"}
					</button>
				</form>
			</Wrapper>
		</ModalComp>
	);
};

export default EditNoteModal;

const Wrapper = styled.div`
	.icons-tab {
		width: 100%;
		min-height: 20px;
		background-color: #f4f4f4;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.text-gray {
		color: #727272;
		font-weight: 400;
		margin: 20px 0;
	}
	.form-textarea {
		height: 100px;
		resize: none;
		padding-top: 6px;
	}
`;
