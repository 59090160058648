import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "moment-timezone";
import { Button } from "components/Button";
import DataTable from "react-data-table-component";
import ViewUserSubModal from "components/users/ViewUserSubModal";
import {
  ITransaction,
  TxnFormattedData,
} from "interfaces/transactions.interface";
import { GET_TRANSACTIONS } from "apollo/transactions.query";
import { CSVLink } from "react-csv";
import Loading from "components/Loading/Loading";
import Empty from "components/Empty/Empty";
import SearchInput from "components/Input/SearchInput";
import moment from "moment";
import useSearchFilter from "components/DataComponent/hooks/useSearchFilter";

const Index = () => {
  const [transaction, setTransaction] = useState<TxnFormattedData | null>(null);
  const [showOutsourceDetails, setShowOutsourceDetails] =
    useState<boolean>(false);
  const [subscribedUsers, setSubscribedUsers] = useState<TxnFormattedData[]>();

  const formatData = (data: ITransaction[]): TxnFormattedData[] => {
    const formattedData: { [key: string]: TxnFormattedData } = {};
    let userCount = 0;

    for (const currentData of data ?? []) {
      const {
        id,
        amount,
        createdAt,
        expiresAt,
        paidAt,
        paymentType,
        plan,
        reference,
        transactionId,
        user,
      } = currentData;

      const transaction = {
        id,
        amount: amount
          ? (amount / 100).toFixed(2).toString()
          : amount.toString(),
        createdAt: moment(createdAt).format(
          "DD/MM/YYYY @ hh:mm A"
        ) as unknown as Date,
        expiresAt: moment(expiresAt).format(
          "DD/MM/YYYY @ hh:mm A"
        ) as unknown as Date,
        paidAt: moment(paidAt).format(
          "DD/MM/YYYY @ hh:mm A"
        ) as unknown as Date,
        paymentType,
        subType: "Monthly",
        status: moment().diff(expiresAt, "minutes") < 0 ? "Active" : "Inactive",
        plan,
        reference,
        transactionId,
      };

      const userId: string = user?.id;

      if (userId) {
        if (formattedData[userId]) {
          formattedData[userId].transactions.push(transaction);
        } else {
          userCount++;
          formattedData[userId] = {
            ...user,
            no: userCount,
            transactions: [transaction],
          };
        }
      }
    }

    return Object.values(formattedData);
  };

  const getUserAccountDetailsHandler = (txn: TxnFormattedData) => {
    setTransaction(txn);
    setShowOutsourceDetails(true);
  };

  const { loading, data } = useQuery(GET_TRANSACTIONS);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = useSearchFilter(subscribedUsers as any, searchQuery, [
    "email",
    "firstName",
    "lastName",
    "department.name",
    "school.name",
  ]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const onClearSearchHandler = () => {
    setSearchQuery("");
    setSearchQuery("");
  };
  useEffect(() => {
    if (data?.getTransactions) {
      const res = formatData(data?.getTransactions);
      setSubscribedUsers(res);
    }

    return () => {
      setSubscribedUsers(undefined);
    };
  }, [data]);

  const columns = [
    {
      name: "No",
      cell: function cell(_: TxnFormattedData, index: number) {
        return <div>{index + 1}</div>;
      },
      sortable: true,
      width: "80px",
    },
    {
      name: "Name of Institution",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.school?.name,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.school?.name}</div>;
      },
    },
    {
      name: "Faculty",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.faculty?.name,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.faculty?.name}</div>;
      },
    },
    {
      name: "Department",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.department?.name,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.department?.name}</div>;
      },
    },
    {
      name: "Email Address",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.email,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.email}</div>;
      },
    },
    {
      name: "Phone Number",
      selector: (row: TxnFormattedData) => row?.phone,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.phone}</div>;
      },
    },
    {
      name: "First Name",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.firstName,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.firstName}</div>;
      },
    },
    {
      name: "Last Name",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.lastName,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.lastName}</div>;
      },
    },
    {
      name: "Gender",
      sortable: true,
      selector: (row: TxnFormattedData) => row?.gender,
      cell: function cell(row: TxnFormattedData) {
        return <div> {row?.gender}</div>;
      },
      width: "100px",
    },
    {
      name: "Subcription Details",
      cell: function cell(row: TxnFormattedData) {
        return (
          <Button
            onClick={() => getUserAccountDetailsHandler(row)}
            size="xsmall"
          >
            View
          </Button>
        );
      },
    },
  ];

  const exportHeaders = [
    { label: "No", key: "no" },
    { label: "Name of institution", key: "school.name" },
    { label: "Faculty", key: "faculty.name" },
    { label: "Department", key: "department.name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Fname", key: "firstName" },
    { label: "Lname", key: "lastName" },
    { label: "Gender", key: "gender" },
    { label: "Sub Date", key: "transactions[0].createdAt" },
    { label: "Exp. Date", key: "transactions[0].expiresAt" },
    { label: "Amount", key: "transactions[0].amount" },
    { label: "Status", key: "transactions[0].status" },
    { label: "Sub. Type", key: "transactions[0].subType" },
    { label: "Sub. Count", key: "subscriptionCount" },
  ];

  const onCloseModal = () => {
    setTransaction(null);
    setShowOutsourceDetails(false);
  };

  return (
    <>
      <ViewUserSubModal
        sub={transaction}
        show={showOutsourceDetails}
        onClose={onCloseModal}
      />
      <Wrapper>
        <div className="d-flex justify-content-between mb-5">
          <SearchInput
            onChange={handleSearchInputChange}
            placeholder="Search anything here. eg. email"
            disabled={loading}
            searchQuery={searchQuery}
            clearInput={onClearSearchHandler}
          />
          <div>
            <CSVLink
              className="btn btn-primary"
              headers={exportHeaders}
              filename={"subscribed-users.csv"}
              data={subscribedUsers ? subscribedUsers : []}
            >
              Export CSV
            </CSVLink>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData as unknown as TxnFormattedData[]}
          noDataComponent={<Empty />}
          pagination={true}
          progressPending={loading}
          progressComponent={<Loading />}
          customStyles={{
            headCells: { style: { color: "#334D6E", fontSize: "13px" } },
          }}
          responsive
          striped
        />
      </Wrapper>
    </>
  );
};

export default Index;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
