import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import 'moment-timezone';
import { GET_USERS } from 'apollo/users.query';
import DataTable from 'react-data-table-component';
import { IUser } from 'interfaces/user.interface';
import Moment from 'react-moment';
import { CSVLink } from 'react-csv';
import SearchInput from 'components/Input/SearchInput';
import useSearchFilter from 'components/DataComponent/hooks/useSearchFilter';
import Empty from 'components/Empty/Empty';
import Loading from 'components/Loading/Loading';

const Index = () => {
  const { loading, data } = useQuery(GET_USERS);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = useSearchFilter(data?.getUsers as any, searchQuery, [
    'email',
    'firstName',
    'lastName',
    'department.name',
    'school.name',
  ]);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onClearSearchHandler = () => {
    setSearchQuery('');
    setSearchQuery('');
  };

  const columns = [
    {
      name: 'No',
      cell: function cell(_: IUser, index: number) {
        return index + 1;
      },
      width: '80px',
    },
    {
      name: 'Name of Institution',
      sortable: true,
      selector: (row: IUser) => row?.school?.name,
      cell: function cell(row: IUser) {
        return <div> {row?.school?.name}</div>;
      },
      width: '200px',
    },
    {
      name: 'Faculty',
      sortable: true,
      selector: (row: IUser) => row?.faculty?.name,
      cell: function cell(row: IUser) {
        return <div> {row?.faculty?.name}</div>;
      },
      width: '150px',
    },
    {
      name: 'Department',
      sortable: true,
      selector: (row: IUser) => row?.department?.name,
      cell: function cell(row: IUser) {
        return <div> {row?.department?.name}</div>;
      },
      width: '200px',
    },
    {
      name: 'Email Address',
      sortable: true,
      selector: (row: IUser) => row?.email,
      cell: function cell(row: IUser) {
        return <div> {row?.email}</div>;
      },
      width: '200px',
    },
    {
      name: 'Phone Number',
      selector: (row: IUser) => row?.phone,
      cell: function cell(row: IUser) {
        return <div> {row?.phone}</div>;
      },
      width: '150px',
    },
    {
      name: 'First Name',
      sortable: true,
      selector: (row: IUser) => row?.firstName,
      cell: function cell(row: IUser) {
        return <div> {row?.firstName}</div>;
      },
      width: '150px',
    },
    {
      name: 'Last Name',
      sortable: true,
      selector: (row: IUser) => row?.lastName,
      cell: function cell(row: IUser) {
        return <div> {row?.lastName}</div>;
      },
      width: '150px',
    },
    {
      name: 'Gender',
      sortable: true,
      selector: (row: IUser) => row?.gender,
      cell: function cell(row: IUser) {
        return <div> {row?.gender}</div>;
      },
      width: '100px',
    },
    {
      name: 'Date Joined',
      sortable: true,
      selector: (row: IUser) => row?.createdAt as unknown as string,
      cell: function cell(row: IUser) {
        return <Moment format="DD/MM/YYYY @ hh:mm A">{row?.createdAt}</Moment>;
      },
      width: '200px',
    },
    {
      name: 'Updated At',
      sortable: true,
      selector: (row: IUser) => row?.updatedAt as unknown as string,
      cell: function cell(row: IUser) {
        return <Moment format="DD/MM/YYYY">{row?.updatedAt}</Moment>;
      },
      width: '150px',

    },
  ];

  const exportHeaders = [
    { label: 'Name of institution', key: 'school.name' },
    { label: 'Faculty', key: 'faculty.name' },
    { label: 'Department', key: 'department.name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Fname', key: 'firstName' },
    { label: 'Lname', key: 'lastName' },
    { label: 'Gender', key: 'gender' },
    { label: 'Date Joined', key: 'createdAt' },
  ];

  return (
    <>
      <Wrapper>
        <div className="d-flex justify-content-between mb-5">
          <SearchInput
            onChange={handleSearchInputChange}
            placeholder="Search anything here. eg. email"
            disabled={loading}
            searchQuery={searchQuery}
            clearInput={onClearSearchHandler}
          />
          <div>
            <CSVLink
              className="btn btn-primary"
              headers={exportHeaders}
              filename={'users.csv'}
              data={filteredData ? filteredData : []}
            >
              Export CSV
            </CSVLink>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={filteredData as unknown as IUser[]}
          noDataComponent={<Empty />}
          pagination={true}
          progressPending={loading}
          progressComponent={<Loading />}
          customStyles={{
            headCells: { style: { color: '#334D6E', fontSize: '13px' } },
            rows: {
              style: { fontSize: '13px' },
            },
          }}
          responsive
          striped
        />
      </Wrapper>
    </>
  );
};

export default Index;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
