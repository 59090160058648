import { gql } from "@apollo/client";

export const GET_COURSE_OUTLINE = gql`
		query ($id: String!) {
			getCourseOutline(id: $id) {
				id
				note
				topic
				course{
					id
					title
				  }
			}
		}
	
`;

export const GET_COURSE_OUTLIN_BY_COURSE = gql`
	query ($id: String!) {
		getCourseOutlinByCourse(course: $id) {
			id
			note
			topic
			course{
				id
				title
			  }
		}
	}
`;

export const CREATE_COURSE_OUTLINE = gql`
	mutation CreateCourseOutline($input: CreateCourseOutlineInput!) {
		createCourseOutline(input: $input) {
			id
			note
			topic
			course{
				id
				title
			}
		}
	}
`;

export const UPDATE_COURSE_OUTLINE = gql`
	mutation UpdateCourseOutline($input: UpdateCourseOutlineInpute!) {
		updateCourseOutline(input: $input) {
			id
			note
			topic
			course{
				id
				title
			}
		}
	}
`;

