import { useMutation } from "@apollo/client";
import { UPDATE_COURSE } from "apollo/course.query";
import { CourseAtom } from "atom/courseAtom";
import ModalComp from "components/ModalComp";
import { ICourse } from "interfaces/school.interface";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	course: ICourse;
}

const EditCourseModal = ({ show, onClose, course }: CreateSchoolProps) => {
	const [updateCourse, { loading }] = useMutation(UPDATE_COURSE);
	const setCourse = useSetRecoilState(CourseAtom);
	const [info, setInfo] = useState<Partial<ICourse>>(course);

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target;
		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = {
			id: info.id,
			code: info.code,
			level: info.level,
			semester: info.semester,
			title: info.title,
		};
		try {
			await updateCourse({ variables: { input } });

			setCourse((old) =>
				old.map((c) => (c.id === info.id ? { ...c, ...info } : c)),
			);
			notifySuccess("Course edited successfully")
			onClose();
		} catch (error) {
			notifyError()
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Edit Course"
			subTitle="Edit and manage course"
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label>Course Title</label>
						<input
							className="form-input"
							onChange={handleChange}
							type="text"
							placeholder="Management"
							name="title"
							value={info?.title}
						/>
					</div>

					<div className="form-section">
						<div className="form-group">
							<label>Course code</label>
							<input
								className="form-input"
								onChange={handleChange}
								type="text"
								placeholder="PHY101"
								name="code"
								value={info?.code}
							/>
						</div>
						<div className="form-group ml-2">
							<label>Level</label>
							<select
								className="form-select"
								onChange={handleChange}
								name="level"
								value={info?.level}
							>
								<option value=""> </option>
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
								<option>6</option>
							</select>
						</div>
						<div className="form-group ml-2">
							<label>Semester</label>
							<select
								className="form-select"
								onChange={handleChange}
								name="semester"
								value={info?.semester}
							>
								<option value=""> </option>
								<option>1st </option>
								<option>2nd </option>
							</select>
						</div>
					</div>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? "Processing..." : "Update Course"}
					</button>
				</form>
			</div>
		</ModalComp>
	);
};

export default EditCourseModal;
