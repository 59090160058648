import { useMutation } from "@apollo/client";
import { CountryAtom } from "atom/country.atom";
import ModalComp from "components/ModalComp";
import { ICountry } from "interfaces/school.interface";
import React, { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UPDATE_COUNTRY } from "../../../apollo/country.query";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";
import Input from "components/Input/Input";

interface IProp {
  show: boolean;
  onClose(): void;
  countryData: ICountry;
}

const EditCountryModal = ({ show, onClose, countryData }: IProp) => {
  const [updateCountry, { loading }] = useMutation(UPDATE_COUNTRY);
  const setCountries = useSetRecoilState(CountryAtom);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [filePreview, setFilePreview] = useState("");
  const [country, setCountry] = useState<Partial<ICountry>>(countryData);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCountry({
      ...country,
      [name]: value,
    });
  };
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const reader = new FileReader();

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
      reader.onloadend = () => {
        if (reader.result) {
          setFilePreview(reader.result as string);
        }
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const input = {
      ...country,
      logo: filePreview ? filePreview : countryData.logo,
    };

    try {
      const { data } = await updateCountry({ variables: { input } });

      setCountries((old) =>
        old.map((country) =>
          country.id === countryData.id
            ? { ...country, ...data.updateCountry }
            : country
        )
      );
      notifySuccess("Country edited successfully");

      onClose();
    } catch (error) {
      notifyError();
      console.log(error);
    }
  };
  return (
    <ModalComp show={show} onClose={onClose} title="Edit Country" subTitle="">
      <div className="create-school">
        <form onSubmit={handleSubmit}>
          <Input
            label="Country name"
            className="form-input mb-3"
            onChange={handleChange}
            type="text"
            placeholder="Nigeria"
            name="name"
            value={country?.name}
          />
          <Input
            label="Alias of Country"
            className="form-input mb-3"
            name="alias"
            type="text"
            onChange={handleChange}
            placeholder="NGN"
            value={country?.alias}
          />

          <div className="form-group" style={{ position: "relative" }}>
            <label
              style={{
                position: "absolute",
                top: "-15px",
                left: "10px",
                padding: "4px",
                background: "white",
                zIndex: "100px",
              }}
            >
              Upload country logo
            </label>
            <div
              className="upload-image"
              onClick={() => uploadRef.current?.click()}
            >
              <img
                src={
                  filePreview
                    ? filePreview
                    : countryData?.logo || "/image-placeholder.png"
                }
                alt="preview"
                width="80"
              />
              <p style={{}}>Upload your image</p>
              <p style={{ fontSize: 12, color: "#9F9F9F", margin: 0 }}>
                Support; JPG, JPEG, PNG
              </p>
            </div>

            <input
              className="d-none"
              type="file"
              onChange={handleImage}
              ref={uploadRef}
            />
          </div>
          <button className="btn btn-primary mt-3" disabled={loading}>
            {loading ? "Processing..." : "Update Country"}
          </button>
        </form>
      </div>
    </ModalComp>
  );
};

export default EditCountryModal;
