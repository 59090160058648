import { useLazyQuery } from '@apollo/client';
import { GET_COURSES_BY_DEPARTMENT } from 'apollo/course.query';
import { CourseAtom } from 'atom/courseAtom';
import DataComponent from 'components/DataComponent/DataComponent';
import GoBackComp from 'components/GoBackComp';
import CourseCardComp from 'components/school/CourseCardComp';
import CreateCourseModal from 'components/school/Modals/CreateCourseModal';
import { ICourse } from 'interfaces/school.interface';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const CoursesPage = () => {
	const [createCourse, setCreateCourse] = useState(false);

	const [courses, setCourses] = useRecoilState(CourseAtom);
	const { id } = useParams<{ id: string }>();
	const [getCourse, { loading }] = useLazyQuery(GET_COURSES_BY_DEPARTMENT, {
		onCompleted: (data) => setCourses(data.getCoursesByDepartment),
		onError: (error) => console.log(error),
		variables: { id, filter: { search: '' } },
	});

	useEffect(() => {
		getCourse({ variables: { id, filter: { search: '' } } });
	}, [getCourse, id]);

	const renderItem = (course: ICourse, index: number) => {
		return <CourseCardComp course={course} key={index} />;
	};

	const abc = () => {
		return (
			<div className="mt-4">
				<div className="flex" style={{ width: '60%' }}>
					<div className="form-section mt-2">
						<div className="form-group me-2">
							<select
								className="form-select form-select-primary "
								onChange={(e) =>
									getCourse({
										variables: { id, filter: { search: e.target.value } },
									})
								}
								name="level"
								style={{ appearance: 'none' }}
							>
								<option value="">Select Level</option>
								<option value={1}>100 Level</option>
								<option value={2}>200 Level</option>
								<option value={3}>300 Level</option>
								<option value={4}>400 Level</option>
								<option value={5}>500 Level</option>
								<option value={6}>600 Level</option>
							</select>
						</div>
						<div className="form-group me-2">
							<select
								className="form-select form-select-primary"
								onChange={(e) =>
									getCourse({
										variables: { id, filter: { search: e.target.value } },
									})
								}
								name="semester"
							>
								<option value="">Select Semester</option>
								<option value="1st">1st Semester</option>
								<option value="2nd">2nd Semester</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<CreateCourseModal show={createCourse} onClose={() => setCreateCourse(false)} />
			<Wrapper>
				<div className="d-flex justify-content-between">
					<div className="flex">
						<GoBackComp />
						<p className="fs-4">Manage Courses</p>
						<p>Select and manage each course</p>
					</div>
					<button className="btn-outline-primary btn" onClick={() => setCreateCourse(true)}>
						+ Add Course
					</button>
				</div>

				<DataComponent
					isLoading={loading}
					data={courses}
					itemsPerPage={6}
					renderItem={renderItem}
					leftSearchComponent={abc}
					searchCol={['title', 'code', 'level', 'semester']}
					parentClass="school-list row"
				/>
			</Wrapper>
		</>
	);
};

export default CoursesPage;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
