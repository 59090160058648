import { ICourse } from "interfaces/school.interface";
import { atom } from "recoil";

export const CourseAtom = atom({
	key: "CourseAtom",
	default: [] as unknown as ICourse[],
});

export const CourseDetailAtom = atom({
	key: "CourseDetailAtom",
	default: [] as unknown as ICourse,
});
