import { useMutation } from "@apollo/client";
import ModalComp from "components/ModalComp";
import React, { useRef, useState } from "react";
import { CREATE_SCHOOL } from "../../../apollo/school.query";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";
import Input from "components/Input/Input";
import { useParams } from "react-router-dom";

interface CreateSchoolProps {
  show: boolean;
  onClose(): void;
  refetch(): void;
}

const CreateSchoolModal = ({ show, onClose, refetch }: CreateSchoolProps) => {
  const [createSchool, { loading }] = useMutation(CREATE_SCHOOL);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [filePreview, setFilePreview] = useState("");
  const { id } = useParams<{ id: string }>();
  const [info, setInfo] = useState({
    name: "",
    alias: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const reader = new FileReader();

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
      reader.onloadend = () => {
        if (reader.result) {
          setFilePreview(reader.result as string);
        }
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const input = {
      ...info,
      logo: filePreview,
      country: id
    };

    try {
      await createSchool({ variables: { input } });
      notifySuccess("Added successfully")
      setInfo({ name: "", alias: "" });
      refetch()
      onClose();
    } catch (error) {
      notifyError()
    }
  };
  return (
    <ModalComp
      show={show}
      onClose={onClose}
      title="Create School"
      subTitle="Add and manage new school"
    >
      <div className="create-school">
        <form onSubmit={handleSubmit}>

          <Input
            label="School name"
            className="form-input  mb-3"
            value={info?.name}
            onChange={handleChange}
            type="text"
            placeholder="University of Port Harcourt"
            name="name"
          />
          <Input
            label="Alias of School"
            className="form-input  mb-3"
            name="alias"
            type="text"
            value={info?.alias}
            onChange={handleChange}
            placeholder="UNIPORT"
          />


          <div className="form-group" style={{ position: "relative" }}>
            <label style={{ position: "absolute", top: "-15px", left: "10px", padding: "4px", background: "white", zIndex: "100px" }}>Upload school logo</label>
            <div
              className="upload-image"
              onClick={() => uploadRef.current?.click()}
            >
              <img
                src={filePreview ? filePreview : "/image-placeholder.png"}
                alt="preview"
                width="80"
              />
              <p style={{}}>Upload your image</p>
              <p style={{ fontSize: 12, color: "#9F9F9F", margin: 0 }}>
                Support; JPG, JPEG, PNG
              </p>
            </div>

            <input
              className="d-none"
              type="file"
              onChange={handleImage}
              ref={uploadRef}
            />
          </div>
          <button className="btn btn-primary mt-3" disabled={loading}>
            {loading ? "Processing..." : "Create School"}
          </button>
        </form>
      </div>
    </ModalComp>
  );
};

export default CreateSchoolModal;
