import { ApolloProvider } from "@apollo/client";
import "@fortawesome/fontawesome-free/css/all.css";
import { GET_ME } from "apollo/auth.query";
import { UserAtom } from "atom/user.atom";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { RecoilRoot, useRecoilState } from "recoil";
import { TOKEN_NAME } from "utils/constants";
import { client } from "./apollo";
import RouterComp from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastContainer } from 'react-toastify';
import "./style/index.scss";

function App() {
	const [user, setUser] = useRecoilState(UserAtom);
	useEffect(() => {
		const getUser = async () => {
			try {
				const { data } = await client.query({
					query: GET_ME,
				});
				setUser(data.me);
			} catch (error) {
				const e = error as any;
				console.log(e?.message);
				Cookies.remove(TOKEN_NAME);
			}
		};
		getUser();
	}, [setUser]);
	return (
		<ApolloProvider client={client}>
			<RecoilRoot>
				<RouterComp isAuth={Boolean(user || Cookies.get(TOKEN_NAME))} />
				<ToastContainer />
			</RecoilRoot>
		</ApolloProvider>
	);
}

export default App;
