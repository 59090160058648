import { gql } from "@apollo/client";

export const GET_WAIT_LISTS = gql`
	query{
		getWaitLists{
			id
			faculty
			department
			email
			phone
			schoolName
			createdAt
		}
	}
`;

