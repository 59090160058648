import { gql } from "@apollo/client";

export const GET_CASE_REPORT = gql`
		query ($id: String!) {
			getCaseReport(id: $id) {
				id
				body
				title
				course{
					id
					title
				  }
			}
		}
	
`;

export const GET_CASE_REPORT_BY_COURSE = gql`
	query ($id: String!) {
		getCaseReportByCourse(course: $id) {
			id
			body
			title
			course{
				id
				title
			}
		}
	}
`;

export const CREATE_CASE_REPORT = gql`
	mutation CreateCaseReport($input: CreateCaseReportInput!) {
		createCaseReport(input: $input) {
			id
			body
			title
			course{
				id
				title
			}
		}
	}
`;

export const UPDATE_CASE_REPORT = gql`
	mutation UpdateCaseReport($input: UpdateCaseReportInput!) {
		updateCaseReport(input: $input) {
			id
			body
			title
			course{
				id
				title
			}
		}
	}
`;

