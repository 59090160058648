import { useLazyQuery } from "@apollo/client";
import { GET_CASE_REPORT_BY_COURSE } from "apollo/cases.query";
import { CasesAtom } from "atom/case.atom";
import DataComponent from "components/DataComponent/DataComponent";
import GoBackComp from "components/GoBackComp";
import CaseCardComp from "components/school/CaseCardComp";
import CreateCaseModal from "components/school/Modals/CreateCaseModal";
import { ICase } from "interfaces/case.interface";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const CourseCase = () => {
    const [createCase, setCase] = useState(false);

    const [cases, setCases] = useRecoilState(CasesAtom);
    const { id } = useParams<{ id: string }>();
    const [getCaseReport, { loading, refetch }] = useLazyQuery(GET_CASE_REPORT_BY_COURSE, {
        onCompleted: (data) => setCases(data.getCaseReportByCourse
        ),
        onError: (error) => console.log(error),
        variables: { id },
    });

    useEffect(() => {
        getCaseReport({ variables: { id } });
    }, [getCaseReport, id]);


    const renderItem = (school: ICase, index: number) => {
        return <CaseCardComp courseCase={school} index={index + 1} key={index} refetch={refetch} />;
    };

    return (
        <div>
            <CreateCaseModal
                show={createCase}
                refresh={refetch}
                onClose={() => setCase(false)}
            />
            <Wrapper>
                <div className="d-flex justify-content-between">
                    <div className="flex">
                        <GoBackComp />
                        <h2 className="fs-4">Manage Case</h2>
                        <p>Add and manage Course Case</p>
                    </div>
                    <button
                        className="btn-outline-primary btn"
                        onClick={() => setCase(true)}
                    >
                        + New Case
                    </button>
                </div>

                <DataComponent
                    isLoading={loading}
                    data={cases}
                    itemsPerPage={6}
                    renderItem={renderItem}
                    searchCol={['title']}
                    parentClass="school-list row"
                />

            </Wrapper>
        </div>
    );
};

export default CourseCase;

const Wrapper = styled.div`
	margin-top:48px;
	.school-list {
		margin-top: 2rem;

		justify-content: space-between;

		img {
			width: 61px;
			height: 58px;
		}
	}
`;
