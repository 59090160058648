import { useLazyQuery } from "@apollo/client";
import { GET_SCHOOLS_BY_COUNTRY } from "apollo/school.query";
import DataComponent from "components/DataComponent/DataComponent";
import CreateSchoolModal from "components/school/Modals/CreateSchoolModal";
import SchoolCardComp from "components/school/SchoolCardComp";
import { ISchool } from "interfaces/school.interface";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SchoolAtom } from "atom/school.atom";

const SchoolPage = () => {
  const [showCreate, setShowCreate] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [schools, setSchools] = useRecoilState(SchoolAtom);

  const [getSchoolsByCountry, { loading, refetch }] = useLazyQuery(
    GET_SCHOOLS_BY_COUNTRY,
    {
      variables: { id },
      onCompleted: (data) => setSchools(data.getSchoolsByCountry),
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    getSchoolsByCountry();
  }, [schools, getSchoolsByCountry]);

  const renderItem = (school: ISchool, index: number) => {
    return <SchoolCardComp school={school} key={index} />;
  };

  return (
    <>
      <CreateSchoolModal
        show={showCreate}
        refetch={refetch}
        onClose={() => setShowCreate(false)}
      />

      <Wrapper>
        <div className="d-flex justify-content-between mt-4">
          <div>
            <h2 className="fs-4">List of Schools </h2>
            <p className="sch-subheading mt-4">Select and Manage School</p>
          </div>
          <button
            className="btn-outline-primary btn rounded"
            onClick={() => setShowCreate(true)}
          >
            + New School
          </button>
        </div>

        <DataComponent
          isLoading={loading}
          data={schools}
          itemsPerPage={6}
          renderItem={renderItem}
          searchCol={["name", "alias"]}
          parentClass="school-list"
        />
      </Wrapper>
    </>
  );
};

export default SchoolPage;

const Wrapper = styled.div`
  .school-list {
    .sch-subheading {
      color: #727272;
    }
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    row-gap: 10px;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
