import { gql, useMutation, useQuery } from '@apollo/client';
import { CREATE_QUESTION } from 'apollo/question.query';
import ModalComp from 'components/ModalComp';
import { IQuestion } from 'interfaces/question.interface';
import { ICourse } from 'interfaces/school.interface';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import MarkdownEditor from '@uiw/react-markdown-editor';
import '../../../style/components/CreateSchool.scss';
import Input from 'components/Input/Input';
import { notifyError, notifySuccess } from 'utils/notification';

const GET_COURSE = gql`
  query ($id: String!) {
    getCourse(id: $id) {
      id
      title
      code
      semester
    }
  }
`;
interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	refetch(): void;
}

const CreateQuestionModal = ({ show, onClose, refetch }: CreateSchoolProps) => {
	const [createQuestion, { loading }] = useMutation(CREATE_QUESTION);
	const [info, setInfo] = useState<Partial<IQuestion>>();
	const { id } = useParams<{ id: string }>();
	const [course, setCourse] = useState<ICourse>();

	useQuery(GET_COURSE, {
		variables: { id },
		onCompleted: (data) => setCourse(data.getCourse),
		onError: (err) => console.log(err),
	});
	const handleMarkDownChange = (name: string, value: string) => {
		setInfo({
			...info,
			[name]: value,
		});
	};
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;

		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = { ...info, course: id };

		try {
			await createQuestion({ variables: { input } });
			refetch();
			notifySuccess('Question created successfully');
			setInfo({ level: '', qns: '', ans: '' });
			onClose();
		} catch (error: any) {
			notifyError(error?.message);
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Questions & Answers"
			subTitle="Add new questions and answers"
		>
			<Wrapper className="create-school">
				<p className="text-gray">Select Course Details</p>
				<div className="icons-tab"></div>
				<form onSubmit={handleSubmit}>
					<div className="question-info">
						<Input
							label="Course Title"
							className="form-input mb-3"
							type="text"
							placeholder="Management"
							value={course?.title}
							disabled={true}
						/>

						<div className="form-group row">
							<select
								className="form-select col-6 mb-3"
								onChange={handleChange}
								name="year"
								value={info?.level}
								required
							>
								<option value="">Select Year</option>
								<option value="2010">2010</option>
								<option value="2011">2011</option>
								<option value="2012">2012</option>
								<option value="2013">2013</option>
								<option value="2014">2014</option>
								<option value="2015">2015</option>
								<option value="2016">2016</option>
								<option value="2017">2017</option>
								<option value="2018">2018</option>
								<option value="2019">2019</option>
								<option value="2020">2020</option>
								<option value="2021">2021</option>
								<option value="2022">2022</option>
								<option value="2023">2023</option>
							</select>
						</div>
					</div>
					<p className="text-gray mt-2">Enter Question & Answer</p>

					<div className="form-group row">
						<label>Question</label>
						<MarkdownEditor
							id="qns"
							style={{ height: '96px' }}
							value={info?.qns}
							onChange={(value) => handleMarkDownChange('qns', value)}
						/>
					</div>
					<div className="form-group">
						<label>Answer</label>
						<MarkdownEditor
							id="ans"
							style={{ height: '96px' }}
							value={info?.ans}
							onChange={(value) => handleMarkDownChange('ans', value)}
						/>
					</div>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? 'Processing...' : 'Save Question'}
					</button>
				</form>
			</Wrapper>
		</ModalComp>
	);
};

export default CreateQuestionModal;

const Wrapper = styled.div`
  .icons-tab {
    width: 100%;
    min-height: 20px;
    background-color: #f4f4f4;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text-gray {
    color: #727272;
    font-weight: 400;
    margin: 20px 0;
  }
  .form-textarea {
    height: 100px;
    resize: none;
    padding-top: 6px;
  }
`;
