import { gql } from "@apollo/client";

export const GET_QUESTIONS = gql`
	{
		getQuestions {
			id
			year
			level
			semester
			qns
			ans
		}
	}
`;

export const GET_QUESTIONS_BY_COURSE = gql`
	query ($id: String!) {
		getQuestionByCourse(course: $id) {
			id
			year
			qns
			ans
			course {
				id
				title
				level
				semester
			}
		}
	}
`;

export const CREATE_QUESTION = gql`
	mutation CreateQuestion($input: CreateQuestionInput!) {
		createQuestion(input: $input) {
			id
			qns
			ans
			year
			course {
				id
				title
				level
				semester
			}
		}
	}
`;

export const UPDATE_QUESTION = gql`
	mutation UpdateQuestion($input: UpdateQestionInput!) {
		updateQuestion(input: $input) {
			id
			qns
			ans
			year
			course {
				id
				title
				level
				semester
			}
		}
	}
`;

export const DELETE_QUESTION = gql`
	mutation ($id: ID) {
		deleteQuestion(id: $id) {
			id
			qns
			ans
		}
	}
`;
