import React from 'react'

function SearchInput({ clearInput = () => { }, searchQuery, ...rest }: { clearInput?: () => void, searchQuery: string, [key: string]: any; }) {


    return (
        <div className=" " style={{ width: "300px" }}>
            <div className="input-group" style={{
                position: 'relative',
                height: '38px',
                display: 'flex',
                background: "#FAFAFA",
                borderRadius: "100px"
            }}>

                <div className="input-group-append">
                    <svg style={{
                        position: 'absolute',
                        top: "3px",
                        left: '10px',
                        height: '38px',
                        zIndex: 100
                    }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2534_18841)">
                            <path d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z" stroke="#74757E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.5 17.5L12.5 12.5" stroke="#74757E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2534_18841">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <input type="text" value={searchQuery} className="form-control" placeholder="Enter your text" aria-label="Enter your text" style={{
                    flexGrow: 1,
                    paddingRight: '0',
                    height: '46px',
                    paddingLeft: "50px",
                    background: "#FAFAFA",
                    borderRadius: "100px"
                }} {...rest} />
                <div className="input-group-append">
                    <div className="btn" onClick={clearInput} style={{
                        position: 'absolute',
                        right: '4px',
                        top: "3px",
                        height: '38px',
                        zIndex: 3,
                    }}>x</div>
                </div>
            </div>
        </div>
    )
}

export default SearchInput