import { useQuery } from "@apollo/client";
import { GET_QUESTIONS_BY_COURSE } from "apollo/question.query";
import { QuestionAtom } from "atom/question.atom";
import DataComponent from "components/DataComponent/DataComponent";
import GoBackComp from "components/GoBackComp";
import CreateQuestionModal from "components/school/Modals/CreateQuestionModal";
import EditQuestionModal from "components/school/Modals/EditQuestionModal";
import QuestionCardComp from "components/school/QuestionCardComp";
import { IQuestion } from "interfaces/question.interface";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const QuestionPage = () => {
  const [createQuestion, setCreateQuestion] = useState(false);

  const [questions, setQuestions] = useRecoilState(QuestionAtom);
  const { id } = useParams<{ id: string }>();
  const { loading, refetch } = useQuery(GET_QUESTIONS_BY_COURSE, {
    onCompleted: (data) => setQuestions(data.getQuestionByCourse),
    onError: (error) => console.log(error),
    variables: { id },
  });

  const [editQuestion, setEditQuestion] = useState<IQuestion | null>(null);
  const [edit, setEdit] = useState(false);

  const showEditForm = (question: IQuestion | null) => {
    setEditQuestion(question);
    setEdit(true);
  };

  const closeEditForm = () => {
    setEditQuestion(null);
    setEdit(false);
  };

  const renderItem = (question: IQuestion, index: number) => {
    return (
      <QuestionCardComp
        question={question}
        showEditForm={showEditForm}
        index={index + 1}
        key={index}
      />
    );
  };

  return (
    <>
      <CreateQuestionModal
        show={createQuestion}
        refetch={refetch}
        onClose={() => setCreateQuestion(false)}
      />

      {editQuestion && (
        <EditQuestionModal
          question={editQuestion}
          show={edit}
          onClose={() => closeEditForm()}
        />
      )}

      <Wrapper>
        <div className="d-flex justify-content-between">
          <div className="flex">
            <GoBackComp />
            <h2 className="fs-4">Manage Questions</h2>
            <p>Add and manage questions</p>
          </div>
          <button
            className="btn-outline-primary btn"
            onClick={() => setCreateQuestion(true)}
          >
            + New Question
          </button>
        </div>

        <DataComponent
          isLoading={loading}
          data={questions}
          itemsPerPage={6}
          renderItem={renderItem}
          searchCol={["qns"]}
          parentClass="school-list row"
        />
      </Wrapper>
    </>
  );
};

export default QuestionPage;

const Wrapper = styled.div`
  margin-top: 48px;
  .school-list {
    margin-top: 2rem;

    justify-content: space-between;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
