import { ApolloClient, InMemoryCache } from "@apollo/client";
import { SERVER_URL, TOKEN_NAME } from "../utils/constants";
import cookie from "js-cookie";

export const client = new ApolloClient({
	uri: `${SERVER_URL}/api/v1/graphql`,
	cache: new InMemoryCache({
		addTypename: false,
		resultCaching: true,
	}),
	headers: {
		authorization: cookie.get(TOKEN_NAME) || "",
	},
});
