import styled from "styled-components"
import ModalComp from "components/ModalComp";
import React from "react";
import { TxnFormattedData } from "interfaces/transactions.interface";
import { CSVLink } from 'react-csv';


interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	sub: TxnFormattedData | null;
}

const ViewUserSubModal = ({ show, onClose, sub }: CreateSchoolProps) => {

	return (
		<ModalComp
			style={{ inner: { padding: "20px 1%" } }}
			show={show}
			hideTopBar={true}
			onClose={onClose}
		>
			<Wrapper>
				<div className="my-5  float-end">
					<CSVLink className='btn btn-primary w-10' filename={"subscriptions.csv"} data={(sub?.transactions) ? sub?.transactions : []}>Export CSV</CSVLink>
				</div>
				<table className="table">
					<thead>
						<tr>
							<th scope="col" >No</th>
							<th scope="col" >Subscription Date</th>
							<th scope="col">Expiration Date</th>
							<th scope="col">Amount</th>
							<th scope="col">Status</th>
							<th scope="col">Subscription Type</th>
						</tr>
					</thead>
					<tbody>
						{sub?.transactions?.map((transaction, index) => (<tr key={transaction.id}>
							<td>{index + 1}</td>
							<td >{transaction.paidAt.toString()}</td>
							<td>{transaction.expiresAt.toString()}</td>
							<td>₦ {transaction.amount}</td>
							<td>{transaction.status}</td>
							<td>Monthly</td>

						</tr>))}

					</tbody>

				</table>

			</Wrapper>
		</ModalComp >
	);
};

export default ViewUserSubModal;

const Wrapper = styled.div`

padding:10px;
	.icons-tab {
		width: 100%;
		min-height: 20px;
		background-color: #f4f4f4;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.text-gray {
		color: #727272;
		font-weight: 400;
		margin: 20px 0;
	}
	.form-textarea {
		height: 100px;
		resize: none;
		padding-top: 6px;
	}

	.card{
		background: #FFFFFF;
		border: 1px solid #F4F4F4;
		border-radius: 4px;
		padding:12px 9px;
	}
`;
