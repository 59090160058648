import { useMutation } from "@apollo/client";
import { UPDATE_FACULTY } from "apollo/faculty.query";
import { FacultyAtom } from "atom/faculty.atom";
import ModalComp from "components/ModalComp";
import { IFaculty } from "interfaces/school.interface";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import "../../../style/components/CreateSchool.scss";
import Input from "components/Input/Input";
import { notifyError, notifySuccess } from "utils/notification";

interface IProps {
	show: boolean;
	onClose(): void;
	facultyData: IFaculty;
}

const EditFacultyModal = ({ show, onClose, facultyData }: IProps) => {
	const [updateFaculty, { loading }] = useMutation(UPDATE_FACULTY);
	const setSchools = useSetRecoilState(FacultyAtom);

	const [faculty, setFaculty] = useState<Partial<IFaculty>>(facultyData);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			await updateFaculty({
				variables: { input: { ...faculty } },
			});

			setSchools((old) =>
				old.map((f) => (f.id === faculty.id ? { ...f, ...faculty } : f)),
			);
			notifySuccess("Faculty edited successfully!")
			onClose();
		} catch (error) {
			notifyError()
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Edit Faculty"
			subTitle="Edit and manage faculty"
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>
					<Input
						label="Faculty name"
						className="form-input mb-3"
						onChange={(e) => setFaculty({ ...faculty, name: e.target.value })}
						type="text"
						placeholder="Management"
						name="name"
						value={faculty.name}
					/>


					<button className="btn btn-primary" disabled={loading}>
						{loading ? "Processing..." : "Update Faculty"}
					</button>
				</form>
			</div>
		</ModalComp>
	);
};

export default EditFacultyModal;
