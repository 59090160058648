import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { TOKEN_NAME } from 'utils/constants';
// import "../style/components/Sidebar.scss";

const SidebarComp = () => {
	const [currentURL, setCurrentURL] = useState('');
	const location = useLocation();

	useEffect(() => {
		setCurrentURL(location.pathname);
	}, [location]);

	return (
		<Wrapper>
			<div id="sidebar-container" className="sidebar-expanded d-none d-md-block">
				<div className="list-group side-main ">
					<div className="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
						<small>
							<img src="/logo.png" alt="" className="logo" />
						</small>
					</div>

					<ul>
						<li className={`list-group  ${currentURL === '/' ? 'active' : 'inactive'}`}>
							<Link
								to="/"
								className={`sidebar-item  ${currentURL === '/' ? 'active' : 'inactive'}`}
							>
								<svg
									className="me-2"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.5 8.13333C1.33431 8.13333 1.2 7.99902 1.2 7.83333V1.5C1.2 1.33431 1.33431 1.2 1.5 1.2H6.16667C6.33235 1.2 6.46667 1.33431 6.46667 1.5V7.83333C6.46667 7.99902 6.33235 8.13333 6.16667 8.13333H1.5ZM1.5 14.8C1.33431 14.8 1.2 14.6657 1.2 14.5V11.5C1.2 11.3343 1.33431 11.2 1.5 11.2H6.16667C6.33235 11.2 6.46667 11.3343 6.46667 11.5V14.5C6.46667 14.6657 6.33235 14.8 6.16667 14.8H1.5ZM9.83333 14.8C9.66765 14.8 9.53333 14.6657 9.53333 14.5V8.16667C9.53333 8.00098 9.66765 7.86667 9.83333 7.86667H14.5C14.6657 7.86667 14.8 8.00098 14.8 8.16667V14.5C14.8 14.6657 14.6657 14.8 14.5 14.8H9.83333ZM9.53333 1.5C9.53333 1.33431 9.66765 1.2 9.83333 1.2H14.5C14.6657 1.2 14.8 1.33431 14.8 1.5V4.5C14.8 4.66569 14.6657 4.8 14.5 4.8H9.83333C9.66765 4.8 9.53333 4.66569 9.53333 4.5V1.5Z"
										stroke="currentColor"
										strokeWidth="1.4"
									/>
								</svg>
								Dashboard Overview
							</Link>
						</li>

						<li className="list-group">
							<a
								href="#management"
								data-bs-toggle="collapse"
								aria-expanded="false"
								className="list-group"
							>
								<div
									className={`d-flex w-100 justify-content-start align-items-center
								
								${currentURL.startsWith('/schools') ||
											currentURL.startsWith('/waitlist') ||
											currentURL.startsWith('/users') ||
											currentURL.startsWith('/subscribers')
											? 'active'
											: 'inactive'
										}
								
								`}
								>
									<span className="menu-collapsed  dropdown-toggle ">
										<svg
											className="me-2"
											width="13"
											height="16"
											fill="none"
											viewBox="0 0 13 16"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.940234 1.33333C0.940234 1.27498 0.951855 1.2301 0.964106 1.2H11.301C11.3132 1.2301 11.3249 1.27498 11.3249 1.33333V6.33333C11.3249 6.39168 11.3132 6.43657 11.301 6.46667H0.964106C0.951855 6.43657 0.940234 6.39168 0.940234 6.33333V1.33333ZM0.940234 9.66667C0.940234 9.60832 0.951855 9.56343 0.964106 9.53333H11.301C11.3132 9.56343 11.3249 9.60832 11.3249 9.66667V14.6667C11.3249 14.725 11.3132 14.7699 11.301 14.8H0.964106C0.951855 14.7699 0.940234 14.725 0.940234 14.6667V9.66667Z"
												stroke="currentColor"
												strokeWidth="1.4"
											/>
										</svg>
										Management
									</span>
								</div>
							</a>
							{/* <!-- Submenu content --> */}
							<div id="management" className="collapse sidebar-submenu">
								<Link
									to="/countries"
									className={`list-group list-group-item-action  mt-3  ps-3  ${currentURL.startsWith('/schools') ? 'active' : 'inactive'
										}`}
								>
									<span className="menu-collapsed">Manage Countries</span>
								</Link>
								<Link
									to="/waitlist"
									className={`list-group list-group-item-action  mt-3  ps-3  ${currentURL.startsWith('/waitlist') ? 'active' : 'inactive'
										}`}
								>
									<span className="menu-collapsed">Manage Waitlist</span>
								</Link>
								<Link
									to="/users"
									className={`list-group list-group-item-action  mt-3  ps-3  ${currentURL.startsWith('/users') ? 'active' : 'inactive'
										}`}
								>
									<span className="menu-collapsed">Manage Users</span>
								</Link>
								<Link
									to="/subscribers"
									className={`list-group list-group-item-action  mt-3  ps-3  ${currentURL.startsWith('/subscribers') ? 'active' : 'inactive'
										}`}
								>
									<span className="menu-collapsed">Manage Subscribers</span>
								</Link>
							</div>
						</li>

						<li className="list-group ">
							<Link
								to="/outsourced"
								className={`d-flex w-100 justify-content-start align-items-center  ${currentURL.startsWith('/outsourced') ? 'active' : 'inactive'
									}`}
							>
								<svg
									className="me-2"
									width="13"
									height="16"
									fill="none"
									viewBox="0 0 13 16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.940234 1.33333C0.940234 1.27498 0.951855 1.2301 0.964106 1.2H11.301C11.3132 1.2301 11.3249 1.27498 11.3249 1.33333V6.33333C11.3249 6.39168 11.3132 6.43657 11.301 6.46667H0.964106C0.951855 6.43657 0.940234 6.39168 0.940234 6.33333V1.33333ZM0.940234 9.66667C0.940234 9.60832 0.951855 9.56343 0.964106 9.53333H11.301C11.3132 9.56343 11.3249 9.60832 11.3249 9.66667V14.6667C11.3249 14.725 11.3132 14.7699 11.301 14.8H0.964106C0.951855 14.7699 0.940234 14.725 0.940234 14.6667V9.66667Z"
										stroke="currentColor"
										strokeWidth="1.4"
									/>
								</svg>
								Outsource
							</Link>
						</li>
					</ul>

					<button
						className="btn text-primary p-0 signout"
						onClick={() => {
							Cookies.remove(TOKEN_NAME);
							window.location.href = '/login';
						}}
					>
						<img className="me-2" src="/icons/logout.svg" alt="" />
						Sign out
					</button>
				</div>
			</div>
		</Wrapper>
	);
};

export default SidebarComp;

const Wrapper = styled.div`
  width: 100%;

  ul {
    padding-top: 100px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    li {
      padding-left: 10px;
      margin-left: 25px;
    }
  }

  .side-main {
    width: 100%;
    height: 100vh;
    display: flex;
    padding-left: 0px !important;
    flex-direction: column;
  }

  .signout {
    position: absolute;
    bottom: 40px;
    left: 25px;
  }

  .active {
    font-size: 13px;
    color: #0090c1;
    font-weight: 400;
  }

  .inactive {
    font-size: 13px;
    color: #727272;
    font-weight: 400;
  }
`;
