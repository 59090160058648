import { useLazyQuery } from "@apollo/client";
import { GET_COUNTRIES } from "apollo/country.query";
import { CountryAtom } from "atom/country.atom";
import DataComponent from "components/DataComponent/DataComponent";
import CountryCardComp from "components/school/CountryCardComp";
import { ICountry } from "interfaces/school.interface";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import CreateCountryModal from "components/school/Modals/CreateCountryModal";

const CountryPage = () => {
  const [showCreate, setShowCreate] = useState(false);

  const [countries, setCountries] = useRecoilState(CountryAtom);

  const [getCountries, { loading, refetch }] = useLazyQuery(GET_COUNTRIES, {
    onCompleted: (data) => setCountries(data.getCountries),
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    getCountries();
  }, [countries, getCountries]);

  const renderItem = (country: ICountry, index: number) => {
    return <CountryCardComp country={country} key={index} />;
  };

  return (
    <>
      <CreateCountryModal
        show={showCreate}
        refetch={refetch}
        onClose={() => setShowCreate(false)}
      />

      <Wrapper>
        <div className="d-flex justify-content-between mt-4">
          <div>
            <h2 className="fs-4">List of Countries </h2>
            <p className="sch-subheading mt-4">Select and Manage Countries</p>
          </div>
          <button
            className="btn-outline-primary btn rounded"
            onClick={() => setShowCreate(true)}
          >
            + New Country
          </button>
        </div>

        <DataComponent
          isLoading={loading}
          data={countries}
          itemsPerPage={6}
          renderItem={renderItem}
          searchCol={["name", "alias"]}
          parentClass="country-list"
        />
      </Wrapper>
    </>
  );
};

export default CountryPage;

const Wrapper = styled.div`
  .country-list {
    .sch-subheading {
      color: #727272;
    }
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    row-gap: 10px;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
