import { gql, useMutation, useQuery } from "@apollo/client";
import ModalComp from "components/ModalComp";
import { ICourse } from "interfaces/school.interface";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MarkdownEditor from '@uiw/react-markdown-editor'
import "../../../style/components/CreateSchool.scss";
import Input from "components/Input/Input";
import { notifyError, notifySuccess } from "utils/notification";
import { CREATE_CASE_REPORT } from "apollo/cases.query";
import { ICase } from "interfaces/case.interface";

const GET_COURSE = gql`
	query ($id: String!) {
		getCourse(id: $id) {
			id
			title
			code
			semester
		}
	}
`;

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	refresh(): void
}

const CreateCaseModal = ({ show, onClose, refresh }: CreateSchoolProps) => {
	const [createCase, { loading }] = useMutation(CREATE_CASE_REPORT);
	const [info, setInfo] = useState<Partial<ICase>>();
	const { id } = useParams<{ id: string }>();
	const [course, setCourse] = useState<ICourse>();

	useQuery(GET_COURSE, {
		variables: { id },
		onCompleted: (data) => setCourse(data.getCourse),
		onError: (err) => console.log(err),
	});

	const handleMarkDownChange = (name: string, value: string) => {

		setInfo({
			...info,
			[name]: value,
		});
	};
	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
	) => {
		const { name, value } = e.target;

		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const input = { ...info, course: id };
		try {
			await createCase({ variables: { input } });
			notifySuccess("Case created successfully")
			refresh()
			setInfo({ title: "", body: "" })
			onClose();
		} catch (error: any) {
			notifyError(error?.message)
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Add Case"
			subTitle="Add and Manage New Cases"
		>
			<Wrapper className="create-school">
				<p className="text-gray">Select Course Details</p>
				<form onSubmit={handleSubmit}>
					<div className="question-info">
						<Input
							label="Course Title"
							className="form-input mb-3"
							type="text"
							placeholder="Management"
							defaultValue="title"
							value={course?.title}
							disabled={true}
						/>
						<Input
							label="Enter Case Citation"
							className="form-input mb-3"
							type="text"
							placeholder="E.g. Alepan v State (19990) 7 NWLR (Part 160) 101"
							onChange={handleChange}
							name="title"
							value={info?.title}
						/>
					</div>
					<p className="text-gray mt-2">Enter Question & Answer</p>

					<div className="form-group row mb-5">
						<label>Paste or Type Course Note Here</label>
						<MarkdownEditor
							id="note"
							style={{ height: "120px" }}
							value={info?.body}
							onChange={(value) => handleMarkDownChange("body", value)}
						/>
					</div>

					<button className="btn btn-primary" disabled={loading}>
						{loading ? "Processing..." : "Save Case"}
					</button>
				</form>
			</Wrapper>
		</ModalComp>
	);
};

export default CreateCaseModal;

const Wrapper = styled.div`
	.icons-tab {
		width: 100%;
		min-height: 20px;
		background-color: #f4f4f4;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.text-gray {
		color: #727272;
		font-weight: 400;
		margin: 20px 0;
	}
	.form-textarea {
		height: 100px;
		resize: none;
		padding-top: 6px;
	}
`;
