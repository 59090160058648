import gql from "graphql-tag";

export const GET_COUNTRIES = gql`
	query {
		getCountries {
			id
			logo
			name
			alias
		}
	}
`;

export const GET_COUNTRY = gql`
	query GetCountryByID($id: ID) {
		getCountry(id: $id) {
			id
			name
			faculties {
				id
				name
			}
		}
	}
`;

export const CREATE_COUNTRY = gql`
	mutation CreateCountry($input: CreateCountryInput! ) {
		createCountry(input: $input) {
			id
			name
			alias
			logo
		}
	}
`;

export const UPDATE_COUNTRY = gql`
	mutation ($input: UpdateCountryInput!) {
		updateCountry(input: $input) {
			id
			name
			logo
			alias
		}
	}
`;
