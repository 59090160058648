import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function UsersByDevice({
  stat,
}: {
  stat: {
    iso: number;
    android: number;
  };
}) {
  const [total, setTotal] = useState<number>(0);
  const [percentage, setPercentage] = useState<{ iso: number; android: number }>({
    iso: 0,
    android: 0,
  });

  useEffect(() => {
    console.log(stat);
    if (stat) {
      const android = Number(stat.android) || 0;
      const iso = Number(stat.iso) || 0;
      const sumTotal = android + iso;
      let androidPercentage = Math.round(((android * 100) / sumTotal) * 10) / 10 || 0;
      let isoPercentage = Math.round(((iso * 100) / sumTotal) * 10) / 10 || 0;

      if (androidPercentage === 0 && isoPercentage === 0) {
        androidPercentage = 50.0;
        isoPercentage = 50.0;
      }

      setPercentage({ android: androidPercentage, iso: isoPercentage });
      setTotal(sumTotal);
    }
  }, [stat]);

  return (
    <Wrapper>
      <div className="main-wrapper">
        <div className="title">Users by Device</div>

        <div className="device-stat-wrapper">
          <p className="android-details">
            <span style={{ color: '#A0AEC1' }}>Android</span>

            <span style={{ fontWeight: '700', fontSize: '20px', color: '#192A3E' }}>
              {`${percentage.android}`}%
            </span>
            <span style={{ color: '#0090C1' }}>{stat.android || 0}</span>
          </p>
          <p className="ios-details">
            <span style={{ color: '#A0AEC1' }}>IOS</span>
            <span style={{ fontWeight: '700', fontSize: '20px', color: '#192A3E' }}>
              {`${percentage.iso}`}%
            </span>
            <span style={{ color: '#dc3912' }}>{stat.iso || 0}</span>
          </p>
        </div>

        <div className="bar">
          <span style={{ background: ' #0090C1', width: `${percentage.android}%` }}></span>
          <span style={{ background: '#dc3912', width: `${percentage.iso}%` }}></span>
        </div>
        <div className="total"> Total: {total}</div>
      </div>
    </Wrapper>
  );
}

export default UsersByDevice;

const Wrapper = styled.div`
  padding: 20px;

  .main-wrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px 20px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }
  .title {
    padding-bottom: 30px;
    border-bottom: 2px solid #e5e5e5;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.01em;
    font-weight: 500;
    width: 100%;
    color:#192A3E;
  }
  .bar {
    width: 100%;
    min-width: 200px;
    height: 48px;
    display: flex;
  }
  .device-stat-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .device-stat-wrapper > p {
    display: flex;
    flex-direction: column;
  }

  .total {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .device-stat-wrapper,
  .bar {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
