import React from 'react'
import PropType from "prop-types"
import styled from 'styled-components'

interface CourseDetailsCardProp {
    course: string
    code: string,
    level: string,
    semester: string
}

const CourseDetailsCard: React.FC<CourseDetailsCardProp> = ({ course, code, level, semester }) => {
    return (
        <Wrapper>
            <div className='course-details-title'>
                <img src="/icons/file.svg" alt="file" />
                <h3>Single Course Details</h3>
            </div>
            <ul>
                <li>Course Title: <span>{course}</span></li>
                <li>Course Code : <span>{code}</span></li>
                <li>Course Level: <span>{level}</span></li>
                <li>Course Semester: <span>{semester}</span></li>

            </ul>
        </Wrapper>
    )
}

CourseDetailsCard.propTypes = {
    course: PropType.string.isRequired,
    code: PropType.string.isRequired,
    level: PropType.string.isRequired,
    semester: PropType.string.isRequired,


}


export default CourseDetailsCard

const Wrapper = styled.div`
	background-color: #fff;
	border-radius: 10px;
	padding: 12px;
    .course-details-title{
        display:flex;
        align-items:center;
        padding-bottom:20px;
    }
    li{
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom:16px;
        span{
            color:#0090C1;
        }
    }

    ul{
        list-style: none

    }
	
`;


// background: #FFFFFF;
// border-radius: 10px;