import { useMutation } from "@apollo/client";
import { CREATE_DEPARTMENT } from "apollo/department.query";
import { DepartmentsAtom } from "atom/department.atom";

import ModalComp from "components/ModalComp";
import { IDepartment } from "interfaces/school.interface";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import "../../../style/components/CreateSchool.scss";
import Input from "components/Input/Input";
import { notifyError, notifySuccess } from "utils/notification";

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
}

const CreateDepartmentModal = ({ show, onClose }: CreateSchoolProps) => {
	const [createDepartment, { loading }] = useMutation(CREATE_DEPARTMENT);
	const setDepartment = useSetRecoilState(DepartmentsAtom);
	const [info, setInfo] = useState<Partial<IDepartment>>();
	const { id } = useParams<{ id: string }>();
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target;
		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const levels = (info?.levels) ? +info?.levels : info?.levels
		const input = { name: info?.name, levels, faculty: id };
		try {
			const { data } = await createDepartment({ variables: { input } });
			setDepartment((old) => [...old, data.createDepartment]);
			notifySuccess("Department created successfully!");
			setInfo({ name: "", levels: "" })
			onClose();
		} catch (error: any) {
			notifyError(error.message)
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Create Department"
			subTitle="Add and manage new department"
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>
					<Input
						label="Department name"
						className="form-input"
						onChange={handleChange}
						value={info?.name}
						type="text"
						placeholder="Management"
						name="name"
						required
					/>
					<Input
						label="Levels"
						className="form-input"
						onChange={handleChange}
						type="number"
						placeholder="1"
						value={info?.levels}
						name="levels"
						required
					/>

					<button className="btn btn-primary mt-5" disabled={loading}>
						{loading ? "Processing..." : "Create Department"}
					</button>
				</form>
			</div>
		</ModalComp>
	);
};

export default CreateDepartmentModal;
