import React, { useEffect, useState } from 'react';
import TopMenu from 'components/TopMenu';
import styled from 'styled-components';
import axios from 'config/axios';
import { Chart } from 'react-google-charts';
import UsersByDevice from 'components/Chart/UsersByDevice';

interface StatsProps {
	allUsers: number;
	adminUsers: number;
	activeUsers: number;
	schools: number;
	faculties: number;
	departments: number;
	cases: number;
	questions: number;
	courseOutlines: number;
	doneAssignments: number;
	doneProjects: number;
	iOSDevices: number;
	activeSubscribers: number;
	androidDevices: number;
}



export const options = {
	legend: 'none',
	pieSliceText: 'label',
	title: 'Subscriptions',
	pieStartAngle: 100,
};

const DashboardHomePage = () => {
	const [stats, setStats] = useState<StatsProps>();
	const [activeSubscribers, setActiveSubscribers] = useState<number>(0);
	const [trialUsers, setTrialUsers] = useState<number>(0);
	useEffect(() => {
		axios.get('/stats').then((data) => setStats(data?.data));
	}, []);

	useEffect(() => {
		if (stats) {
			const { activeSubscribers, allUsers, adminUsers } = stats || {};
			const trial = allUsers - adminUsers - activeSubscribers;
			setTrialUsers(trial);
			setActiveSubscribers(activeSubscribers);
		}
	}, [stats]);

	const data = [
		['Language', 'Speakers (in millions)'],
		['Paid', activeSubscribers],
		['Trial', trialUsers],
	];

	return (
		<Wrapper>
			<TopMenu stats={stats} />

			<div className="stats-total">
				<div className="stat">
					<div className="stat-title"> Stat</div>
					<hr />

					<div className="device-sub" >

						<UsersByDevice
							stat={{
								android: stats?.androidDevices as number,
								iso: stats?.iOSDevices as number,
							}}
						/>

						<Chart
							chartType="PieChart"
							data={data}
							options={options}
							width={'100%'}
							height={'400px'}
						/>

					</div>
					<div className="user-stat">{/* <p>Users Stats</p> */}</div>
				</div>
				<div className="total-content">
					<h2>Total Contents Created</h2>

					<ul>
						<li>
							Cases Added <span className="line"></span>{' '}
							<span className="value"> {(stats && stats.cases) || 0}</span>
						</li>
						<li>
							Questions Added <span className="line"></span>{' '}
							<span className="value"> {(stats && stats.questions) || 0}</span>
						</li>
						<li>
							Lesson Notes <span className="line"></span>{' '}
							<span className="value"> {(stats && stats.courseOutlines) || 0}</span>
						</li>
						<li>
							Assignments Done <span className="line"></span>{' '}
							<span className="value"> {(stats && stats.doneAssignments) || 0}</span>
						</li>
						<li>
							Projects Done <span className="line"></span>{' '}
							<span className="value"> {(stats && stats.doneProjects) || 0}</span>
						</li>
					</ul>
				</div>
			</div>
			<br />
		</Wrapper>
	);
};

export default DashboardHomePage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
    padding: 0px;
  }
  .banner > img {
    height: 100%;
    width: 100%;
  }
  .stats-total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;

    .stat,
    .total-content {
      background: #fff;
    }

    .stat {
      width: 60%;
    }
    .stat-title {
      padding-top: 30px;
      padding-left: 30px;
      font-size: 15px;
      color: #192a3e;
    }

    .device-sub {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
    }

    .total-content {
      width: 40%;
      font-size: 14px;
      padding: 40px;
      h2 {
        font-size: 16px;
        margin-bottom: 29px;
      }
      li {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        align-items: center;
        margin-bottom: 11px;
      }

      .line {
        width: 100%;
        height: 8px;
        background: #0090c1;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: flex;
      }
      .value {
        text-align: right;
      }
    }
  }
`;
