import { useQuery } from '@apollo/client';
import { GET_DEPARTMENTS_BY_FACULTY } from 'apollo/department.query';
import { DepartmentsAtom } from 'atom/department.atom';
import DataComponent from 'components/DataComponent/DataComponent';
import DepartmentCardComp from 'components/DepartmentCardComp';
import GoBackComp from 'components/GoBackComp';
import CreateDepartmentModal from 'components/school/Modals/CreateDepartmentModal';
import { IDepartment } from 'interfaces/school.interface';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const DepartmentPage = () => {
  const [createDept, setCreateDept] = useState(false);

  const { id } = useParams<{ id: string }>();
  const [departments, setDepartments] = useRecoilState(DepartmentsAtom);
  const { loading } = useQuery(GET_DEPARTMENTS_BY_FACULTY, {
    variables: { id },
    onCompleted: (data) => setDepartments(data.getDepartmentsByFaculty),
    onError: (error) => console.log(error),
  });

  const renderItem = (department: IDepartment, index: number) => {
    return <DepartmentCardComp key={index} department={department} />;
  };

  return (
    <>
      <CreateDepartmentModal show={createDept} onClose={() => setCreateDept(false)} />
      <Wrapper>
        <div className="d-flex justify-content-between">
          <div className="flex">
            <GoBackComp />
            <h2 className="fs-4 mt-5">Select Department</h2>
            <p>Select and manage Department then proceed</p>
          </div>
          <button
            className="btn-outline-primary btn rounded-lg"
            onClick={() => setCreateDept(true)}
          >
            + New Department
          </button>
        </div>

        <DataComponent
          isLoading={loading}
          data={departments}
          itemsPerPage={6}
          renderItem={renderItem}
          parentClass="department-list"
        />
      </Wrapper>
    </>
  );
};

export default DepartmentPage;

const Wrapper = styled.div`
  margin-top: 48px;
  .department-list {
    margin-top: 2rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;

    img {
      width: 61px;
      height: 58px;
    }
  }
`;
