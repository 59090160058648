import { useMutation } from "@apollo/client";
import { UPDATE_DEPARTMENT } from "apollo/department.query";
import { DepartmentsAtom } from "atom/department.atom";
import ModalComp from "components/ModalComp";
import { IDepartment } from "interfaces/school.interface";
import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import "../../../style/components/CreateSchool.scss";
import { notifyError, notifySuccess } from "utils/notification";
import Input from "components/Input/Input";

interface CreateSchoolProps {
	show: boolean;
	onClose(): void;
	department: IDepartment;
}

const EditDepartmentModal = ({
	show,
	onClose,
	department,
}: CreateSchoolProps) => {
	const [updateDepartment, { loading }] = useMutation(UPDATE_DEPARTMENT);
	const setDepartment = useSetRecoilState(DepartmentsAtom);
	const [info, setInfo] = useState<Partial<IDepartment>>(department);

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target;
		setInfo({
			...info,
			[name]: value,
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const levels = (info?.levels) ? +info?.levels : info?.levels
		const input = { ...info, levels };
		try {
			await updateDepartment({ variables: { input } });

			setDepartment((old) =>
				old.map((d) => (d.id === department.id ? { ...d, ...info } : d)),
			);
			notifySuccess("Department edited successfully");
			onClose();
		} catch (error: any) {
			notifyError(error.message)
		}
	};
	return (
		<ModalComp
			show={show}
			onClose={onClose}
			title="Edit Department"
			subTitle="Edit and manage department"
		>
			<div className="create-school">
				<form onSubmit={handleSubmit}>

					<Input
						label="Department name"
						className="form-input"
						onChange={handleChange}
						type="text"
						placeholder="Management"
						name="name"
						value={info?.name}
					/>
					<Input
						label="Levels"
						className="form-input"
						onChange={handleChange}
						type="number"
						placeholder="Management"
						name="levels"
						value={info?.levels}
					/>

					<button className="btn btn-primary mt-3" disabled={loading}>
						{loading ? "Processing..." : "Update Department"}
					</button>
				</form>
			</div >
		</ModalComp >
	);
};

export default EditDepartmentModal;
