import gql from "graphql-tag";

export const CREATE_FACULTY = gql`
	mutation ($input: CreateFacultyInput!) {
		createFaculty(input: $input) {
			id
			name
		}
	}
`;

export const GET_FACULTIES = gql`
	{
		getFaculties {
			id
			name
		}
	}
`;

export const GET_FACULTIES_BY_SCHOOL = gql`
	query ($id: String!) {
		getFacultiesBySchool(school: $id) {
			id
			name
		}
	}
`;

export const UPDATE_FACULTY = gql`
	mutation ($input: UpdateFacultyInput!) {
		updateFaculty(input: $input) {
			id
			name
		}
	}
`;

export const DELETE_FACULTY = gql`
	mutation ($id: ID) {
		deleteFaculty(id: $id) {
			id
			name
		}
	}
`;
