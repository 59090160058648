import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

interface StatsProps {
	allUsers: number
	adminUsers: number
	activeUsers: number
	schools: number
	faculties: number
	departments: number

}

interface TopMenuProps {
	stats: StatsProps | undefined
}


const TopMenu: React.FunctionComponent<TopMenuProps> = ({ stats }) => {

	return (
		<Wrapper>
			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.allUsers) || 0}</p>
				<div className="description">Registered Users</div>
			</div>
			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.adminUsers) || 0}</p>
				<div className="description">Admin</div>
			</div>

			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.activeUsers) || 0}</p>
				<div className="description">Active users</div>
			</div>
			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.schools) || 0}</p>
				<div className="description">Schools</div>
			</div>
			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.faculties) || 0}</p>
				<div className="description">Faculties</div>
			</div>
			<div className="section">
				<img src="/icons/user-icon.svg" alt="user icon" />
				<p className="value">{(stats && stats.departments) || 0}</p>
				<div className="description">Departments</div>
			</div>
		</Wrapper>
	);
};

TopMenu.propTypes = {
	stats: PropTypes.shape({
		allUsers: PropTypes.number.isRequired,
		adminUsers: PropTypes.number.isRequired,
		activeUsers: PropTypes.number.isRequired,
		schools: PropTypes.number.isRequired,
		faculties: PropTypes.number.isRequired,
		departments: PropTypes.number.isRequired,
	})
}

export default TopMenu;


const Wrapper = styled.div`
	
	padding-top: 26px;
	border-radius: 10px;
	width:100%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 8px;
	margin-bottom:8px;

	.section {
	
		background-color: #fff;
		
		height:140px;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		img{
			width:44px;
			height:28px;
		}

	
		.description {
			color: #727272;
			font-size:14px;
		
		}
		.value {
			font-size: 40px;
			color: #0090C1;
		}
		

		
	}
`;
