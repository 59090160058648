import { IUser } from "interfaces/user.interface";
import { atom } from "recoil";

export const UserAtom = atom({
	key: "UserAtom",
	default: null as unknown as IUser,
});
export const UsersAtom = atom({
	key: "UsersAtom",
	default: null as unknown as IUser[],
});
