import { useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { TOKEN_NAME } from "utils/constants";
import { LOGIN } from "../apollo/auth.query";
import "../style/login.scss";

const LoginPage = () => {
	const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
	const [login, { loading }] = useMutation(LOGIN);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
	};
	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			const { data } = await login({
				variables: { email: loginInfo.email, password: loginInfo.password }
			});

			if (data?.login?.role !== "Admin") {
				alert("Forbidden");
			} else {
				Cookies.set(TOKEN_NAME, data.login.token);
				window.location.href = "/";
			}

		} catch (err) {
			console.log({ err });
			const e = err as any;
			if (e?.graphQLErrors?.[0]?.message) {
				alert(e?.graphQLErrors?.[0]?.message);
			}
		}
	};
	return (
		<div className="login-page">
			<div>
				<img src="/logo.png" className="logo" alt="" />
				<h1 className="main-text">
					Gain Access to Tutornia’s
					<br /> Admin Dashboard
				</h1>
				<p className="light-text">
					If you don’t have an account you could <a href="#request">request</a> from
					<br /> the Management
				</p>

				<div className="login-form">
					<form onSubmit={handleLogin}>
						<input
							type="email"
							className="form-input email"
							placeholder="Email Address"
							value={loginInfo?.email}
							name="email"
							onChange={handleChange}
						/>
						<input
							type="password"
							className="form-input password"
							placeholder="Password"
							value={loginInfo?.password}
							name="password"
							onChange={handleChange}
						/>
						<button disabled={loading} className="btn btn-primary">
							{loading ? "Processing" : "Sign in"}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
